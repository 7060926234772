import React, { useEffect, useRef } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { ThemeProvider, iqTheme } from '@statsbomb/kitbag-components';
import { useReactiveVar } from '@apollo/client';
import { ProtectedRoute } from '../../apollo/ProtectedRoute';
import {
  ui_isDark,
  currentLocationRV,
  ui_scoutSetups,
  mf_Teams,
  mf_Leagues,
  mf_LeagueLevel,
} from '../../apollo';
import { GroupOnlyRoute } from '../../apollo/GroupOnlyRoute';
import Layout from '../Layout/Layout';
import ReleaseNotes from '../../pages/ReleaseNotes/ReleaseNotes';

import Player from '../../pages/player/Player';
import PlayerOverview from '../../pages/player/PlayerOverview/PlayerOverview';
import PlayerPassPlacement from '../../pages/player/PlayerPassPlacement/PlayerPassPlacement';
import PassEnds from '../../pages/player/PlayerPassing/PassEnds';
import PlayerRadar from '../../pages/player/PlayerRadar/PlayerRadar';
import PlayerSnap from '../../pages/player/PlayerSnap/PlayerSnap';

import BoxScore from '../../pages/team/TeamBoxScore/TeamBoxScore';
import PassPressures from '../../pages/team/lineBattles/PassPressures/PassPressures';
import TeamPassing from '../../pages/team/TeamPassing/TeamPassing';
import TeamDepthChart from '../../pages/team/TeamDepthChart/TeamDepthChart';
import TeamFormationSummary from '../../pages/team/TeamFormationSummary/TeamFormationSummary';

import PlayerStats from '../../pages/League/PlayerStats/PlayerStats';
import TeamStats from '../../pages/League/TeamStats/TeamStats';

import Game from '../../pages/Game/Game';
import GameAnimation from '../../pages/Game/GameAnimation/GameAnimation';
import GameSnapFormations from '../../pages/Game/GameSnapFormations/GameSnapFormations';
import PlayByPlay from '../../pages/Game/GamePlayByPlay/PlayByPlay';
import GamePlayEvents from '../../pages/Game/GamePlayEvents/GamePlayEvents';
import DataAccess from '../../pages/DataAccess/DataAccess';

import SafePaletteViewer from '../../pages/Dev/SafePaletteViewer';

import '../../styles/index.scss';
import { USER_GROUP } from '../../utils/constants/auth';
import TeamTackleAttempts from '../../pages/team/TeamTackling/TeamTackleAttempts';
import TeamHavoc from '../../pages/team/TeamHavoc/TeamHavoc';
import { getEnrichedTheme } from '../../utils/visualisations/visPalettes';
import TeamPlusMinus from '../../pages/team/TeamPlusMinus/TeamPlusMinus';
import RunTendencies from '../../pages/team/lineBattles/RunTendencies/RunTendencies';
import TeamOverview from '../../pages/team/TeamOverview/TeamOverview';
import TeamRadar from '../../pages/team/TeamRadar/TeamRadar';
import TeamFormationDetail from '../../pages/team/TeamFormationDetail/TeamFormationDetail';
import PlayerRoute from '../../pages/player/PlayerRoute/PlayerRoute';
import TeamSchedule from '../../pages/team/TeamSchedule/TeamSchedule';
import ScoutLandingPage from '../../pages/Scout/wizard/ScoutLandingPage';
import ScoutGuidedSetup from '../../pages/Scout/wizard/ScoutGuidedSetup';
import ScoutResults from '../../pages/Scout/ScoutResults/ScoutResults';
import { PRODUCTION, PAGE } from './index.constants';
import PlayerComparison from '../../pages/player/PlayerComparison/PlayerComparison';
import Admin from '../../pages/Admin/Admin';
import BasicPage from '../Layout/BasicPage';
import useUserConfig from '../../utils/hooks/useUserConfig';
import useAccountConfig from '../../utils/hooks/useAccountConfig';
import PlayFinder from '../../pages/PlayFinder/PlayFinder';
import PlayFinderWizard from '../../pages/PlayFinder/PlayFinderWizard/PlayFinderWizard';
import PlayFinderResults from '../../pages/PlayFinder/PlayFinderResults/PlayFinderResults';
import HavocSummary from '../../pages/team/TeamHavoc/TmpHavocSummary/HavocSummary';
import TeamDefensiveScheme from '../../pages/team/TeamDefensiveScheme/TeamDefensiveScheme';
import { pitchCompetitionLevel } from '../../utils/helpers/field.constants';

const App = function () {
  const { account: userAccount } = useUserConfig();
  const { account: teamAccount } = useAccountConfig();
  const ui_isDarkRV = useReactiveVar(ui_isDark);
  const location = useLocation();
  const firstLoad = useRef(false);
  const { DEFAULT, WITH_CONTEXT_FILTERS } = PAGE;

  useEffect(() => {
    currentLocationRV(location.pathname);
  }, [location]);

  useEffect(() => {
    if (userAccount) {
      ui_isDark(userAccount.isDark);
      ui_scoutSetups(userAccount?.scoutSetups);
    }
  }, [userAccount]);

  useEffect(() => {
    if (firstLoad.current) {
      // invoke account saved defaults if the URL is http://{TLD}/
      // else they are overridden (in <Layout>) by the URL supplied params
      if (
        location.pathname.length <= 1 &&
        teamAccount?.defaultCompetition &&
        teamAccount?.defaultTeam
      ) {
        mf_Leagues(teamAccount.defaultCompetition);
        mf_LeagueLevel(pitchCompetitionLevel(teamAccount.defaultCompetition));
        mf_Teams(teamAccount.defaultTeam);
      }
    }
  }, [teamAccount]);

  useEffect(() => {
    firstLoad.current = true;
  }, []);

  const theme = ui_isDarkRV ? iqTheme.dark : iqTheme.light;
  const enrichedTheme = getEnrichedTheme(theme);

  if (process.env.NODE_ENV === PRODUCTION) {
    Sentry.init({
      dsn: process.env.SENTRY_DSN,
      integrations: [
        new Sentry.BrowserTracing({
          tracePropagationTargets: [/^https:\/\/iq-amf.statsbomb\.com/],
        }),
      ],
      // Performance Monitoring
      tracesSampleRate: Number(process.env.SENTRY_TRACES_SAMPLE_RATE), // 1.0 = capture 100% of the transactions
    });
  }

  return (
    <ThemeProvider theme={enrichedTheme}>
      <Routes>
        <Route element={<ProtectedRoute component={Layout} />}>
          <Route element={<BasicPage top={WITH_CONTEXT_FILTERS.top} />}>
            <Route path="" element={<TeamOverview />} />
            <Route path="data-access" element={<DataAccess />} />
          </Route>
          <Route
            path="/:leagues/:seasons/:teams"
            element={<BasicPage top={WITH_CONTEXT_FILTERS.top} />}
          >
            <Route path="" element={<TeamOverview />} />
          </Route>
          <Route
            path="player"
            element={<Player top={WITH_CONTEXT_FILTERS.top} />}
          >
            <Route
              path="overview/:leagues/:seasons/:teams/:players"
              element={<PlayerOverview />}
            />
            <Route
              path="placement/:leagues/:seasons/:teams/:players"
              element={<PlayerPassPlacement />}
            />
            <Route
              path="snap/:leagues/:seasons/:teams/:players"
              element={<PlayerSnap />}
            />
            <Route
              path="radar/:leagues/:seasons/:teams/:players"
              element={<PlayerRadar />}
            />
            <Route
              path="route/:leagues/:seasons/:teams/:players"
              element={<PlayerRoute />}
            />
            <Route
              path="passing/:leagues/:seasons/:teams/:players"
              element={<PassEnds />}
            />
          </Route>
          <Route path="player" element={<Player top={DEFAULT.top} />}>
            <Route
              path="comparison/:leagues/:seasons/:teams/:players"
              element={<PlayerComparison />}
            />
          </Route>
          <Route
            path="team"
            element={<BasicPage top={WITH_CONTEXT_FILTERS.top} />}
          >
            <Route path="box/:leagues/:seasons/:teams" element={<BoxScore />} />
            <Route
              path="defensiveScheme/:leagues/:seasons/:teams"
              element={<TeamDefensiveScheme />}
            />
            <Route
              path="linePressure/:leagues/:seasons/:teams"
              element={<PassPressures />}
            />
            <Route
              path="runTendencies/:leagues/:seasons/:teams"
              element={<RunTendencies />}
            />
          </Route>
          <Route path="team" element={<BasicPage top={DEFAULT.top} />}>
            <Route
              path="schedule/:leagues/:seasons/:teams"
              element={<TeamSchedule />}
            />
          </Route>
          <Route
            path="team"
            element={<BasicPage top={WITH_CONTEXT_FILTERS.top} />}
          >
            <Route
              path="overview/:leagues/:seasons/:teams"
              element={<TeamOverview />}
            />
            <Route
              path="passing/:leagues/:seasons/:teams"
              element={<TeamPassing />}
            />
            <Route
              path="havoc/:leagues/:seasons/:teams"
              element={<TeamHavoc />}
            />
            <Route
              path="havoc-summary/:leagues/:seasons/:teams"
              element={
                <GroupOnlyRoute group={USER_GROUP.STATSBOMB}>
                  <HavocSummary />
                </GroupOnlyRoute>
              }
            />
            <Route
              path="tackles/:leagues/:seasons/:teams"
              element={<TeamTackleAttempts />}
            />
            <Route
              path="depth/:leagues/:seasons/:teams"
              element={<TeamDepthChart />}
            />
            <Route
              path="summary/:leagues/:seasons/:teams"
              element={<TeamFormationSummary />}
            />
            <Route
              path="detail/:leagues/:seasons/:teams"
              element={
                <GroupOnlyRoute group={USER_GROUP.STATSBOMB}>
                  <TeamFormationDetail />
                </GroupOnlyRoute>
              }
            />
            <Route
              path="impact/:leagues/:seasons/:teams"
              element={
                <GroupOnlyRoute group={USER_GROUP.STATSBOMB}>
                  <TeamPlusMinus />
                </GroupOnlyRoute>
              }
            />
            <Route
              path="radar/:leagues/:seasons/:teams"
              element={<TeamRadar />}
            />
          </Route>
          <Route
            path="team"
            element={<BasicPage top={WITH_CONTEXT_FILTERS.top} />}
          >
            <Route
              path="overview/:leagues/:seasons/:teams"
              element={<TeamOverview />}
            />
            <Route
              path="passing/:leagues/:seasons/:teams"
              element={<TeamPassing />}
            />
            <Route
              path="havoc/:leagues/:seasons/:teams"
              element={<TeamHavoc />}
            />
            <Route
              path="havocSummary/:leagues/:seasons/:teams"
              element={
                <GroupOnlyRoute group={USER_GROUP.STATSBOMB}>
                  <HavocSummary />
                </GroupOnlyRoute>
              }
            />
          </Route>
          <Route path="playfinder" element={<PlayFinder top={DEFAULT.top} />}>
            <Route path="wizard/" element={<PlayFinderWizard />} />
            <Route
              path="results/:leagues/:seasons/:teams/:offense"
              element={<PlayFinderResults />}
            />
            <Route
              path="results/:leagues/:seasons/:teams/:offense/:preset"
              element={<PlayFinderResults />}
            />
          </Route>
          <Route
            path="league"
            element={<BasicPage top={WITH_CONTEXT_FILTERS.top} />}
          >
            <Route path="stats">
              <Route
                path="players/:leagues/:seasons"
                element={<PlayerStats />}
              />
              <Route path="teams/:leagues/:seasons" element={<TeamStats />} />
            </Route>
          </Route>
          <Route path="game" element={<Game />}>
            <Route
              path="animation/:leagues/:seasons/:teams/:games/:drives/:plays"
              element={<GameAnimation />}
            />
            <Route
              path="formations/:leagues/:seasons/:teams/:games/:drives"
              element={<GameSnapFormations />}
            />
            <Route
              path="event/:leagues/:seasons/:teams/:games/:drives/:plays"
              element={<GamePlayEvents />}
            />
          </Route>
          <Route path="game" element={<BasicPage top={DEFAULT.top} />}>
            <Route
              path="byplay/:leagues/:seasons/:teams/:games"
              element={<PlayByPlay />}
            />
          </Route>
          <Route path="scout" element={<BasicPage top={DEFAULT.top} />}>
            <Route
              path="wizard/:leagues/:seasons"
              element={<ScoutLandingPage />}
            />
            <Route
              path="guide/:leagues/:seasons"
              element={<ScoutGuidedSetup />}
            />
            <Route
              path="players/:leagues/:seasons"
              element={<ScoutResults />}
            />
          </Route>
          <Route path="help">
            <Route path="release-notes" element={<ReleaseNotes />} />
          </Route>
          {/* This page is internal use only so should never appear in any menu */}
          <Route path="safe" element={<BasicPage top={DEFAULT.top} />}>
            <Route
              path="palette/:leagues/:seasons"
              element={
                <GroupOnlyRoute group={USER_GROUP.STATSBOMB}>
                  <SafePaletteViewer />
                </GroupOnlyRoute>
              }
            />
          </Route>
          <Route path="/admin" element={<BasicPage top={DEFAULT.top} />}>
            <Route path="" element={<Admin />} />
          </Route>
        </Route>
      </Routes>
    </ThemeProvider>
  );
};

export default App;
