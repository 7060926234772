import { curveCatmullRom, line } from 'd3';
import { min, max } from 'lodash';
import { VISUALISATION_STYLE_CLICKABLE_OBJECT_CLASS } from '../../utils/constants/charting';

/*
Draws vertical bars 
  yOrigin specifies the point from which bars should be drawn (e.g. when you want above and below)
  yOrigin can be left null, in which case the bars will draw from top or bottom of the chart 
    inverted is bottom up because SVG y=0 is top, so inverted scale is "normal" bar chart
TODO: make horizontal bars equivalent option
TODO: stacked bars
*/
export const drawBars = (
  canvasG,
  barData,
  xScale,
  yScale,
  yOrigin,
  selectedBar,
  handleBarClick
) => {
  const minY = min(yScale.range());
  const maxY = max(yScale.range());
  const yIsInverted = minY !== yScale.range()[0];
  const getY = (yValue) => {
    if (yOrigin || yOrigin === 0) {
      /* Drawing bars around a fixed point */
      const scaledValue = yScale(yValue);
      const scaledOrigin = yScale(yOrigin);
      return min([scaledValue, scaledOrigin]);
    }

    /* If no yOrigin then drawing from the axis */
    if (yIsInverted) {
      /* Drawing from bottom upwards */
      return yScale(yValue);
    }
    /* Drawing from the top */
    return yScale.range()[0];
  };

  const getRectHeight = (yValue) => {
    if (minY === maxY) {
      return 0;
    }
    if (yOrigin || yOrigin === 0) {
      return Math.abs(yScale(yValue) - yScale(yOrigin));
    }
    if (yIsInverted) {
      return Math.abs(yScale(yValue) - maxY);
    }
    return Math.abs(yScale(yValue) - minY);
  };

  canvasG
    .selectAll('rect')
    .data(barData, (d) => d.id)
    .join(
      (enter) =>
        enter
          .append('rect')
          .attr('class', VISUALISATION_STYLE_CLICKABLE_OBJECT_CLASS)
          .on('click', (_, datum) => handleBarClick(datum)),
      (update) =>
        update
          .attr('class', VISUALISATION_STYLE_CLICKABLE_OBJECT_CLASS)
          .on('click', (_, datum) => handleBarClick(datum)),
      (exit) => exit.remove()
    )
    .transition()
    .duration(600)
    .attr('x', (d) => xScale(d.xValue))
    .attr('y', (d) => getY(d.yValue))
    .attr('width', xScale.bandwidth())
    .attr('height', (d) => getRectHeight(d.yValue))
    .attr('fill', (d) => d.fill)
    .attr('stroke', (d) => d.stroke)
    .attr('stroke-width', 1)
    .attr('opacity', (d) =>
      selectedBar !== null && d.id !== selectedBar ? 0.4 : 1
    );
};

/*
Draws VERTICAL bars that fill the whole canvas but are invisible
  Used to support titles and any clickable functionality
TODO: enable Horizontal support
*/
export const drawInteractionBars = (
  canvasG,
  barData,
  xScale,
  layout,
  selectedBar,
  handleBarClick
) => {
  canvasG
    .selectAll('rect')
    .data(barData, (d) => d.id)
    .join(
      (enter) => {
        const r = enter.append('rect');
        r.append('title').text((barDatum) => barDatum.title);
        r.attr('class', VISUALISATION_STYLE_CLICKABLE_OBJECT_CLASS);
        r.on('click', (_, datum) => handleBarClick(datum));
        return r;
      },
      (update) => {
        update.selectAll('title').remove();
        update.append('title').text((barDatum) => barDatum.title);
        update.attr('class', VISUALISATION_STYLE_CLICKABLE_OBJECT_CLASS);
        update.on('click', (_, datum) => handleBarClick(datum));
        return update;
      },
      (exit) => exit.remove()
    )
    .attr('x', (d) => xScale(d.xValue))
    .attr('y', 0)
    .attr('width', xScale.bandwidth())
    .attr('height', layout.CANVAS.HEIGHT)
    .attr('fill', 'transparent')
    .attr('stroke', 'none')
    .attr('opacity', (d) =>
      selectedBar !== null && d.id !== selectedBar ? 0.4 : 1
    );
};

/*
Draws a smoothly curved line joining the values (assumes sensibly ordered)
TODO: This should probably live in a LineChart.drawing function but rest of line chart not
  currently required/built
*/
export const drawLines = (canvasG, linesData, xScale, yScale) => {
  canvasG
    .selectAll('path')
    .data(linesData, (d) => d.id)
    .join(
      (enter) => enter.append('path'),
      (update) => update,
      (exit) => exit.remove()
    )
    .transition()
    .duration(600)
    .style('stroke', (d) => d.stroke.color)
    .style('stroke-width', (d) => d.stroke.width)
    .style('stroke-dasharray', (d) => d.stroke.dashArray)
    .attr('fill', 'transparent')
    .attr('d', (d) => {
      const pathLocations = d.data.map((z) => {
        const pair = [xScale(z.xValue), yScale(z.yValue)];
        return pair;
      });
      return line().curve(curveCatmullRom)(pathLocations);
    });
};
