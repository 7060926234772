import { styled } from 'styled-components';
import { HEADER_HEIGHT } from '../Header/Header.styles';

export const SIDEBAR_WIDTH = '3.5rem';

export const StyledSideNavigation = styled.div`
  z-index: 103;

  aside {
    position: fixed;
    top: 0;
    height: ${({ isMobileOpen }) => isMobileOpen && '100%'};
    min-height: ${HEADER_HEIGHT};
  }

  nav {
    header {
      height: ${HEADER_HEIGHT};
      min-height: ${HEADER_HEIGHT};
    }

    img {
      max-width: inherit;
      color: inherit;
    }
    ul {
      background-color: ${({ theme, isSubmenuOpen }) =>
        isSubmenuOpen && theme.colours.navigation.secondary.main};
    }
    // fix to allow long lists to scroll in the mobile side nav with three footer items
    a + div {
      max-height: calc(-16rem + 100vh);
      overflow: hidden auto;
    }
  }

  footer {
    display: unset;
    container: unset;
    border-right: ${({ isMobileOpen }) => isMobileOpen && 'unset'};
    & > section > ul,
    & > ul {
      padding-bottom: ${({ theme }) => theme.sizing.small};
      background-color: ${({ theme, isSubmenuFooterOpen }) =>
        isSubmenuFooterOpen && theme.colours.navigation.secondary.main};
    }
    & > hr {
      color: transparent;
    }
  }
`;

export const StyledBadge = styled.span`
  display: inline-flex;
  vertical-align: text-top;
  padding-left: 0.5rem;
`;
