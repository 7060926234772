import { clamp, isEmpty } from 'lodash';
import { curveBasis, line } from 'd3';
import {
  ROTATIONS,
  VISUALISATION_STYLE_CLICKABLE_OBJECT_CLASS,
} from '../../utils/constants/charting';
import { csValue } from '../../utils/helpers/colorScales';
import { drawCircle } from '../../utils/visualisations/shapes';
import {
  HAVOC_COLOR_MODE_LOCATIONS,
  HAVOC_COLOR_MODE_LOCATIONS_GAPS,
  HAVOC_COLOR_MODE_LOCATIONS_PLAYERS,
  HAVOC_COLOR_MODE_LOCATIONS_POSITIONS,
  HAVOC_COLOR_MODE_LOCATIONS_TIME,
  HAVOC_EVENT_TYPES,
  HAVOC_TYPE_PRESSURE,
  HAVOC_TYPE_RUN_DISRUPTION,
  HAVOC_TYPE_SACK,
} from './HavocChart.constants';
import { gapColor } from '../../utils/constants/gaps';

export const drawHavocEvents = ({
  svgG,
  havocData,
  orientation,
  visPalette,
  colorMode,
  playersWithColors,
  showPaths,
  setSelectedEvents,
  isInteractive,
  resetMarquee,
  completeMarquee,
  nowPlayingData,
}) => {
  const pathsG = svgG.append('g');
  const dotsG = svgG.append('g');

  const havocTransform = (havocDatum) => {
    const translate = `translate(${havocDatum.x},${havocDatum.y})`;
    if (
      havocDatum.havocType === HAVOC_TYPE_SACK.value &&
      orientation !== ROTATIONS.HORIZONTAL
    ) {
      return `${translate} rotate(90)`;
    }
    if (
      havocDatum.havocType === HAVOC_TYPE_PRESSURE.value ||
      havocDatum.havocType === HAVOC_TYPE_RUN_DISRUPTION.value
    ) {
      const rotationToTarget = (havocDatum?.angleLoS || 0) + 90; // adjust for desired
      return `${translate} rotate(${rotationToTarget})`;
    }
    return translate;
  };
  const radius = 3;
  const havocShape = (havocDatum) => {
    const havocTypeShape =
      HAVOC_EVENT_TYPES[havocDatum.havocType]?.shape(radius);
    return havocTypeShape || drawCircle(radius);
  };

  const havocColor = (havocDatum, isBorder = false) => {
    if (colorMode === HAVOC_COLOR_MODE_LOCATIONS_TIME.value) {
      const clampedTime = clamp(havocDatum?.postSnapSeconds || 1, 1, 6);
      const timeFrac = (clampedTime - 1) / 5;
      return csValue(timeFrac);
    }
    if (colorMode === HAVOC_COLOR_MODE_LOCATIONS_PLAYERS.value) {
      const playerWithColor = playersWithColors.find(
        (p) => p.playerId === havocDatum.playerId
      );
      if (playerWithColor) {
        return playerWithColor.color;
      }
      return visPalette.objects.neutral.main;
    }
    if (colorMode === HAVOC_COLOR_MODE_LOCATIONS.value) {
      return HAVOC_EVENT_TYPES[havocDatum.havocType].color(visPalette);
    }
    if (colorMode === HAVOC_COLOR_MODE_LOCATIONS_POSITIONS.value) {
      if (typeof havocDatum?.position?.color === 'function') {
        const positionColor = havocDatum.position.color(visPalette)?.main;
        return positionColor;
      }
      return visPalette.positions.reserve.r.main;
    }
    if (colorMode === HAVOC_COLOR_MODE_LOCATIONS_GAPS.value) {
      return gapColor(havocDatum?.snapGap, visPalette, isBorder);
    }

    /* Safety valve */
    return visPalette.objects.neutral.main;
  };

  dotsG
    .selectAll('path')
    .data(havocData)
    .enter()
    .append('svg:path')
    .attr('transform', (d) => havocTransform(d))
    .attr('d', (d) => havocShape(d))
    .attr('stroke', (d) => havocColor(d, true))
    .attr('stroke-width', 1)
    .attr('fill', (d) => havocColor(d))
    .attr('class', isInteractive && VISUALISATION_STYLE_CLICKABLE_OBJECT_CLASS)
    .on('click', (_, d) => {
      if (isInteractive) {
        setSelectedEvents([d.eventUUID]);
        resetMarquee();
      }
    })
    .on('mouseup', () => {
      completeMarquee();
    });

  if (!isEmpty(nowPlayingData)) {
    dotsG
      .selectAll('circle')
      .data(nowPlayingData)
      .enter()
      .append('circle')
      .attr('transform', (d) => havocTransform(d))
      .attr('cx', 0)
      .attr('cy', 0)
      .attr('r', 8)
      .attr('fill', 'transparent')
      .attr('stroke', visPalette.selectedObject)
      .attr('stroke-width', 2);
  }

  if (showPaths) {
    pathsG
      .selectAll('path')
      .data(havocData)
      .enter()
      .append('svg:path')
      .attr('d', (d) =>
        d?.defenderPath?.length
          ? line().curve(curveBasis)(d.defenderPath)
          : 'M0,0'
      )
      .attr('stroke', (d) => havocColor(d))
      .attr('stroke-dasharray', '2 2')
      .attr('stroke-width', '0.5')
      .attr('fill', 'transparent');
  }
};
