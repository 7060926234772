import {
  SideNavigation,
  SideNavigationFooter,
  SideNavigationHeader,
} from '@statsbomb/kitbag-components';
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { capitalizeFirstLetter } from '../../utils/helpers/general';
import { sideNavFooterItems, sideNavItems } from './SideNav.config';
import { StyledSideNavigation } from './sideNav.styles';
import LogoLink from '../Logo/Logo';
import SideNavMenuList from './SideNavMenuList';
import LogoIcon from '../../assets/logos/logoIcon.svg';
import LogoText from '../../assets/logos/logoText.svg';

const SideNav = () => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [selectedSubmenu, setSelectedSubmenu] = useState(null);
  const [selectedSubmenuFooter, setSelectedSubmenuFooter] = useState(null);

  const isMobile = width <= 767;
  const currentLocation = location.pathname.slice(1);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };
  const formattedCurrentLocation = currentLocation
    .split('/')[0]
    .split('-')
    .map((word) => capitalizeFirstLetter(word))
    .join(' ');

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);
  return (
    <StyledSideNavigation
      isOpen={isOpen}
      isMobileOpen={isOpen && isMobile}
      isMobile={isMobile}
      isSubmenuOpen={Boolean(selectedSubmenu)}
      isSubmenuFooterOpen={Boolean(selectedSubmenuFooter)}
    >
      <SideNavigation
        version={2}
        isOpen={isOpen}
        isTouch={isMobile}
        title={formattedCurrentLocation}
      >
        <SideNavigationHeader
          isOpen={isOpen}
          isTouch={isMobile}
          logoIcon={
            <LogoLink
              id="logoIcon"
              logo={LogoIcon}
              alt="Statsbomb AMF IQ Logo"
            />
          }
          logoText={
            <LogoLink
              id="logoText"
              logo={LogoText}
              alt="Statsbomb AMF IQ Logo"
            />
          }
          onClick={isMobile ? () => setIsOpen(!isOpen) : undefined}
        />
        <SideNavMenuList
          menuListItems={sideNavItems}
          isMobile={isMobile}
          currentLocation={currentLocation}
          selectedSubmenu={selectedSubmenu}
          setSelectedSubmenu={setSelectedSubmenu}
        />

        <SideNavigationFooter
          component={SideNavMenuList}
          componentProps={{
            menuListItems: sideNavFooterItems,
            isMobile,
            currentLocation,
            selectedSubmenu: selectedSubmenuFooter,
            setSelectedSubmenu: setSelectedSubmenuFooter,
          }}
        />
      </SideNavigation>
    </StyledSideNavigation>
  );
};

export default SideNav;
