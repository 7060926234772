import { isEmpty, maxBy, minBy, reverse, sumBy } from 'lodash';
import { scaleLinear } from 'd3';
import {
  csDivergent,
  csIntensity,
} from '../../../../utils/helpers/colorScales';
import { API_OL_GAPS } from '../../../../utils/constants/api';
import { LINE_GAPS } from '../../../../utils/constants/gaps';
import { safeDivide } from '../../../../utils/helpers/maths';
import { HAVOC_SUMMARY_COLORING_MDOES } from './HavocBars.constants';
import { havocEventsToGroupedHavocPlays } from '../HavocPlays.dataManipulation';

/** Turns data from general format (table ready) to shape expected for a bar chart
 * colorMode needs League Average values (as does title)
 *
 * League Average values are coming in a later PR, some placeholder logic is here so coloring
 *  setup can be used/tested already
 */
const prepGapSummary = ({
  gapData,
  gapAverages,
  percentageSetup,
  colorMode,
  isDark,
}) => {
  /* Get relevant coloring function */

  /* When a zone is selected, what fraction of the total (for zone) is this gap (vs other gaps) */
  const totValue = sumBy(gapData, percentageSetup.dataKey);
  const maxValue =
    maxBy(gapData, percentageSetup.dataKey)?.[percentageSetup.dataKey] || 1;
  const minValue =
    minBy(gapData, percentageSetup.dataKey)?.[percentageSetup.dataKey] || 0;
  /* 
    Team intensity: bar coloring is relative to max value
        Find max/min values to set the scale
  */
  const valueTeamFractionScaler = scaleLinear()
    .domain([minValue, maxValue])
    .range([0, 1]);

  /* 
    Vs LA: bar coloring is relative to LA
    value will be anything from 0 to infinity, but clamp at 2 (double the LA) so divergence is
        uniform and symmetrical
  */
  const laValueScaler = scaleLinear().domain([0, 2]).range([0, 1]).clamp(true);

  /* Want all gaps always, so start from the (ordered) gaps then add data */
  const rtlGaps = reverse(Object.values(API_OL_GAPS));
  const barData = rtlGaps.map((gapCode) => {
    const gapDatum = gapData?.find((g) => g.key === gapCode);
    const laDatum = gapAverages?.find((g) => g.gapCode === gapCode);
    const laValue = laDatum?.[percentageSetup.dataKey] || 0;

    const gap = LINE_GAPS[gapCode];
    const value = gapDatum?.[percentageSetup.dataKey] || 0;
    /* scale 0-1 of gap value vs team values */
    const valueTeamFraction = valueTeamFractionScaler(value);
    /* scale 0-1 of gap value vs la value for gap */
    const valueLAFraction = laValueScaler(safeDivide(value, laValue));

    const title =
      `${gap.name}` +
      `\n${percentageSetup.label}: ${(value * 100)?.toFixed(1)}` +
      `\nLeague Average: ${(laValue * 100)?.toFixed(1)}`;

    const fill =
      colorMode === HAVOC_SUMMARY_COLORING_MDOES.TEAM_INTENSITY.value
        ? csIntensity(valueTeamFraction, isDark)
        : csDivergent(valueLAFraction, isDark);

    const valFrac = safeDivide(value, totValue); // used for sankey pipes later
    return {
      id: gap.apiCode[0],
      xValue: gap.name,
      yValue: value,
      title,
      fill,
      stroke: 'transparent',
      valFrac,
      valueTeamFraction,
      valueLAFraction,
    };
  });

  return barData;
};

/*
Converts events into grouped data, and then re-formats that data for bar chart (scales/colors it)
If a defender zone selection is made, the bars have background-mode bars that display the unfiltered values
*/
export const getBarData = ({
  havocData,
  gapAverages,
  playSummary,
  selectedDefenderZones,
  aggregationMode, // snap gap or exploited gap
  percentageSetup, // HAVOC_SUMMARY_PERCENTAGE_TYPES
  colorMode, // HAVOC_SUMMARY_COLORING_MDOES
  isDark,
}) => {
  /* Always have the basic gap data */
  const { groupData: gapData } = havocEventsToGroupedHavocPlays({
    havocData,
    aggregationMode,
    playSummary,
  });
  const rawBarData = prepGapSummary({
    gapData,
    gapAverages,
    percentageSetup,
    colorMode,
    isDark,
  });

  /* repeat for just the relevant filtered info if selection has been made via defender zone */
  if (!isEmpty(selectedDefenderZones)) {
    const selectedDZoneData = havocData.filter((d) =>
      selectedDefenderZones.includes(d.defenderZoneAPICode)
    );

    const { groupData: filteredGapData } = havocEventsToGroupedHavocPlays({
      havocData: selectedDZoneData,
      aggregationMode,
      playSummary,
    });
    const filteredBarData = prepGapSummary({
      gapData: filteredGapData,
      gapAverages,
      percentageSetup,
      colorMode,
      isDark,
    });

    return { barData: filteredBarData, unfilteredBarData: rawBarData };
  }
  return { barData: rawBarData, unfilteredBarData: rawBarData };
};
