import {
  ButtonIcon,
  Dropdown,
  Grid,
  Typography,
} from '@statsbomb/kitbag-components';
import React, { useState, useEffect } from 'react';
import { Message, Loader } from 'semantic-ui-react';
import { useReactiveVar } from '@apollo/client';
import { sumBy } from 'lodash';
import { useWindowWidth } from '@react-hook/window-size';
import { useTheme } from 'styled-components';
import {
  FixedAside,
  KitbagPageGridHolder,
  SidebarRightLayout,
} from '../../../../components/Layout/Layout.styles';
import AccordionTile from '../../../../components/Accordion/AccordionTile';
import Tile from '../../../../components/Tile/Tile';
import KeyButton from '../../../../components/buttons/DisplayKey/DisplayKey';
import Dimmer from '../../../../components/Dimmer/Dimmer';
import { mf_TeamDetails } from '../../../../apollo';
import {
  getGapData,
  getAverageTotals,
  getPlayersLists,
  getPOAData,
  getSelectedPlayersData,
  getFilterSelectedPlayers,
  getRunGapPlays,
} from './RunTendencies.dataManipulation';
import {
  RUN_CHARTS_WIDTH,
  RUN_TENDENCY_AXES,
  RUN_TENDENCY_AXES_OPTIONS,
  RUN_TENDENCY_POSITION_KEYS,
  SELECTED_PLAYERS,
} from './RunTendencies.constants';
import { FilterRow } from './offensePlayerFilters/FilterRow';
import RunGapScatter from './RunGapScatter/RunGapScatter';
import RunPOAHistogram from './RunPOAHistogram/RunPOAHistogram';
import useQueryString from '../../../../utils/hooks/useQueryString';
import {
  useGetRunGapData,
  useGetRunLeagueAverageData,
} from './RunTendencies.hooks';
import RunTable from './RunTable/RunTable';
import { RUN_TENDENCY_TABLE_MODES } from './RunTable/RunTable.constants';
import RunKey from './RunKey/RunKey';
import {
  ChartButtonRibbon,
  RunTendenciesContainer,
  POAChartTitle,
} from './RunTendencies.styles';
import ExportModal from '../../../../components/ExportModal/ExportModal';
import { OLGapPosition } from '../../../../visualisations/OLPressure/OLGapPosition/OLGapPosition';
import { CHART_LAYOUT } from '../../../../visualisations/BasicChart/BasicChart.constants';
import { useExportSeasonInfo } from '../../../../utils/hooks/useExportSeasonInfo';
import PageHeader from '../../../../components/PageHeader/PageHeader';
import {
  SummaryTile,
  SummaryTileBody,
  SummaryTileHeader,
} from '../../../../components/Tile/TileSummary.styles';
import { MemoizedVideoTile } from '../../../../components/VideoTile/VideoTile';

import { API_STAT_UNITS } from '../../../../utils/constants/api';
import { useGetPlayPlayers } from '../../../../utils/hooks/useGetPlaysPlayersVideo';
import { formatValue } from '../../../../utils/helpers/stats.dataManipulation';

const RunTendencies = function () {
  // selected team and comp
  const selectedTeamDetails = useReactiveVar(mf_TeamDetails);
  const [displayTable, setDisplayTable] = useState(false);
  const [displayKey, setDisplayKey] = useQueryString('showkey', true);
  const [selectedPlayers, setSelectedPlayers] = useState(SELECTED_PLAYERS);
  const [bubbleYAxis, setBubbleYAxis] = useQueryString(
    'bubbleYAxis',
    RUN_TENDENCY_AXES.yardsGained.value
  );
  const [bubbleRAxis, setBubbleRAxis] = useQueryString(
    'bubbleRAxis',
    RUN_TENDENCY_AXES.playPercentage.value
  );
  const [bubbleColoring, setBubbleColoring] = useQueryString(
    'bubbleColoring',
    RUN_TENDENCY_AXES.successRate.value
  );
  const [distroYAxis, setDistroYAxis] = useQueryString(
    'distroYAxis',
    RUN_TENDENCY_AXES.playPercentage.value
  );
  const [distroColoring, setDistroColoring] = useQueryString(
    'distroColoring',
    RUN_TENDENCY_AXES.successRate.value
  );
  // video integration
  const theme = useTheme();
  const visPalette = theme.colours.visualisations;
  const [selectedPlays, setSelectedPlays] = useState([]);
  // selectedPlays are in the format:
  /* { playIds: ['1234', '5678'], 
      id: 'Gap A', 
      title: 'Team Data: 1', 
      subTitle: '1 plays (1% of filtered plays), 1 yard gained', 
      isBubble: true 
      } */
  // the title and subtitle are used in the video tile
  // isBubble is a boolean to determine if the play is a gap play (bubble chart) or a POA play (histogram)
  // the playIds are passed to useGetPlayPlayers() to get the video player data for each play
  const [videoOpen, setVideoOpen] = useState(false);
  const windowWidth = useWindowWidth();
  const [sidebarWidthCalc, setSidebarWidthCalc] = useState();
  const [horizontalKey, setHorizontalKey] = useState(false);

  /* Team data from API */
  const { teamRunGapData, teamRunPOAData, totalPlays, loading, error } =
    useGetRunGapData();

  /* League average from API */
  const {
    leagueRunGapData,
    leagueRunPOAData,
    loading: loadingLA,
    error: errorLA,
  } = useGetRunLeagueAverageData();
  const isError = !!error || !!errorLA;
  const isLoading = loading || loadingLA;
  /* Average Totals (bubble chart values), League and Team */
  const leagueAverageTotals = getAverageTotals(leagueRunGapData);
  const teamAverageTotals = getAverageTotals(
    teamRunGapData,
    leagueAverageTotals
  );

  /* Set up the player selections */
  const playersLists = getPlayersLists(teamRunPOAData);
  /* Get Gap and POA data matching player selections */
  const selectedPlayersRunData = getSelectedPlayersData(
    teamRunGapData,
    selectedPlayers
  );
  const selectedPlayersPOAData = getSelectedPlayersData(
    teamRunPOAData,
    selectedPlayers
  );

  const selectedPlayerDetails = getFilterSelectedPlayers(
    playersLists,
    selectedPlayers
  );

  /* Final formatting for charts */
  const playTotals = {
    all: totalPlays,
    gapsFiltered: sumBy(selectedPlayersRunData, 'plays'),
    gapsTeam: sumBy(teamRunGapData, 'plays'),
    poaFiltered: sumBy(selectedPlayersPOAData, 'plays'),
    poaTeam: sumBy(teamRunPOAData, 'plays'),
  };
  const playsTitleAll = `${selectedTeamDetails?.name} ${playTotals.all}`;
  const playsTitleGaps =
    playTotals.gapsFiltered !== playTotals.gapsTeam
      ? `${playTotals.gapsFiltered}/${playTotals.gapsTeam}`
      : `${playTotals.gapsTeam}`;
  const playsTitlePOA =
    playTotals.poaFiltered !== playTotals.poaTeam
      ? `${playTotals.poaFiltered}/${playTotals.poaTeam}`
      : `${playTotals.poaFiltered}`;
  const playsTitle = `${playsTitleAll} run plays, ${playsTitleGaps} gap plays, ${playsTitlePOA} POA plays`;
  /* Bubbles */
  const gapDataLeague = getGapData(
    leagueRunGapData,
    sumBy(leagueRunGapData, 'plays')
  );
  const gapDataTeam = getGapData(
    teamRunGapData,
    playTotals.gapsTeam,
    gapDataLeague
  );
  const gapData = getGapData(
    selectedPlayersRunData,
    playTotals.gapsFiltered,
    gapDataLeague
  );
  // extract plays for use in video
  const gapPlayIds = getRunGapPlays(gapData);
  /* Distro */
  const poaDataLeague = getPOAData(
    leagueRunPOAData,
    sumBy(leagueRunPOAData, 'plays')
  );
  const poaDataTeam = getPOAData(
    teamRunPOAData,
    playTotals.poaTeam,
    poaDataLeague
  );
  const poaData = getPOAData(
    selectedPlayersPOAData,
    playTotals.poaFiltered,
    poaDataLeague
  );

  /* Titles for the two charts */
  const gapChartTitle = `Run Gaps: ${RUN_TENDENCY_AXES[bubbleYAxis].label}`;
  const poaChartTitle = `Run Point of Attack: ${RUN_TENDENCY_AXES[distroYAxis].label}`;
  /* Y-axis name used in keys for average lines */
  const hideScatterAverageLines = [
    RUN_TENDENCY_AXES.plays.value,
    RUN_TENDENCY_AXES.playPercentage.value,
  ].includes(bubbleYAxis);
  const gapLinesMetricName = hideScatterAverageLines
    ? null
    : RUN_TENDENCY_AXES[bubbleYAxis].label;
  const poaLinesMetricName = RUN_TENDENCY_AXES[distroYAxis].label;

  /* For export mode, when positionally filtering, render the gaps/positions diagram */
  const canvasStart =
    CHART_LAYOUT.AXES.AREA.left + CHART_LAYOUT.AXES.PADDING.left;
  const canvasIgnore = CHART_LAYOUT.CANVAS.WIDTH / 6 / 4; // 41.667 ~ accounts for auto axis internal padding
  const olGapOverrides = {
    visualisationWidth: RUN_CHARTS_WIDTH,
    leftSpacing: canvasStart + canvasIgnore,
    symbolAreaWidth: (CHART_LAYOUT.CANVAS.WIDTH - canvasIgnore * 2) / 11, // 6 gaps + 5 positions
  };
  /* Export mode title * details */
  const ballCarrierDetails =
    selectedPlayers[RUN_TENDENCY_POSITION_KEYS.ballCarrier] > 0 &&
    playersLists[RUN_TENDENCY_POSITION_KEYS.ballCarrier]?.find(
      (p) => p.value === selectedPlayers[RUN_TENDENCY_POSITION_KEYS.ballCarrier]
    );
  const ballCarrierName =
    selectedPlayers[RUN_TENDENCY_POSITION_KEYS.ballCarrier] > 0
      ? `Ball Carrier: ${ballCarrierDetails?.player}`
      : '';
  const exportDetails = {
    title: playsTitle,
    secondaryTitle: 'Run Tendencies',
    info1: useExportSeasonInfo(),
    info2: ballCarrierName,
    info3: null,
    fileName: `${selectedTeamDetails?.name} run tendencies`,
  };

  useEffect(() => {
    setSidebarWidthCalc(windowWidth - (videoOpen ? 0.5 : 0.85) * windowWidth);
    // transition delay for closing panel
    // KitBag lg: '61.9375rem', // 991px
    if (!videoOpen && windowWidth > 991) {
      setTimeout(() => {
        setHorizontalKey(false);
      }, 500); // matches SidebarRightLayout transition time
    } else {
      setHorizontalKey(windowWidth <= 1950 || videoOpen);
    }
  }, [windowWidth, videoOpen]);

  // RunGapScatter chart bubble click handler
  const onBubbleClick = (datum) => {
    // remove whitespace from ID to map onto object
    const trimmedId = datum.id.replace(/\s+/g, '');
    if (datum && gapPlayIds[trimmedId].length > 0) {
      const { playPercentage, yardsGained, plays } = gapData.find(
        (f) => f.id === datum.id
      );
      setSelectedPlays({
        playIds: gapPlayIds[trimmedId],
        id: datum.id,
        title: `Run Gap ${datum.id}: Yards Gained`,
        subTitle: `${plays} play${plays.length === 1 ? '' : 's'} (${formatValue(
          playPercentage,
          API_STAT_UNITS.PERCENTAGE
        )} of filtered plays), ${formatValue(
          yardsGained,
          API_STAT_UNITS.YARDS
        )} yards gained`,
        isBubble: true,
      });
      setVideoOpen(true);
    } else {
      setSelectedPlays(null);
      setVideoOpen(false);
    }
  };

  // RunPOAHistogram chart bar click handler
  const onBarClick = (datum) => {
    const { playPercentage, yardsGained, playIds } = poaData.find(
      (f) => f.id === datum.id
    );
    if (playIds?.length > 0) {
      setSelectedPlays({
        playIds,
        id: datum.id,
        title: `Run Point of Attack: ${datum.id} yards ${
          datum.id < 0 ? 'left' : 'right'
        } of snap`,
        subTitle: `${playIds?.length} play${
          playIds?.length === 1 ? '' : 's'
        } (${formatValue(
          playPercentage,
          API_STAT_UNITS.PERCENTAGE
        )} of filtered plays), ${formatValue(
          yardsGained,
          API_STAT_UNITS.YARDS
        )} yards gained`,
        isBubble: false,
      });
      setVideoOpen(true);
    } else {
      setSelectedPlays(null);
      setVideoOpen(false);
    }
  };
  const playsVideo = useGetPlayPlayers(
    selectedPlays?.playIds || [],
    visPalette
  );

  return (
    <div className="team-run-tendencies-page">
      <KitbagPageGridHolder>
        <Grid container={false} page>
          <Grid item xs={12}>
            <SidebarRightLayout
              $sidebarWidth={`${sidebarWidthCalc}px`}
              $inlineSize={`${videoOpen ? 40 : 16}%`}
              $gap="0.5rem"
              $padding="0 0.25rem 0 0"
            >
              <div>
                <PageHeader
                  href="/team/runTendencies/:leagues/:seasons/:teams/"
                  rootPage="Team"
                  activePage="Run Tendencies"
                  customTitle={
                    selectedTeamDetails?.name && `${selectedTeamDetails?.name}`
                  }
                  showPlayerName={false}
                  showTeamName={false}
                />

                <SummaryTile $height="auto" $margin="0.5rem 0">
                  <SummaryTileHeader>
                    <div>
                      <h3>{playsTitle}</h3>
                    </div>
                    <div>
                      <ButtonIcon
                        id="vis-or-table-button"
                        size="small"
                        title={
                          displayTable
                            ? 'Display Visualisation'
                            : 'Display Table'
                        }
                        icon="Swap"
                        variant="secondary"
                        onClick={() => setDisplayTable(!displayTable)}
                      />
                      <KeyButton
                        showKey={displayKey}
                        handleShowKey={() => setDisplayKey(!displayKey)}
                      />
                      <ExportModal
                        title={exportDetails.title}
                        secondaryTitle={exportDetails.secondaryTitle}
                        info1={exportDetails.info1}
                        info2={exportDetails.info2}
                        info3={exportDetails.info3}
                        fileName={exportDetails.fileName}
                        isDisabled={
                          loading || !gapData?.length || !poaData?.length
                        }
                        customWidth={RUN_CHARTS_WIDTH}
                      >
                        {selectedPlayerDetails?.length > 0 && (
                          <OLGapPosition
                            selectedPlayers={selectedPlayerDetails}
                            widthOverrides={olGapOverrides}
                            hidePlayerInfo={false}
                          />
                        )}
                        <Typography variant="headingMedium">
                          {gapChartTitle}
                        </Typography>
                        <RunGapScatter
                          runGapData={gapData}
                          runGapDataTeam={gapDataTeam}
                          teamAverageTotals={teamAverageTotals}
                          leagueAverageTotals={leagueAverageTotals}
                          yAxisKey={bubbleYAxis}
                          rAxisKey={bubbleRAxis}
                          colorAxisKey={bubbleColoring}
                        />
                        {displayKey && (
                          <RunKey
                            isVertical={false}
                            scalingAxisSetup={RUN_TENDENCY_AXES[bubbleRAxis]}
                            colorAxisSetup={RUN_TENDENCY_AXES[bubbleColoring]}
                            linesMetricName={gapLinesMetricName}
                          />
                        )}

                        <Typography variant="headingMedium">
                          {poaChartTitle}
                        </Typography>
                        <RunPOAHistogram
                          poaData={poaData}
                          poaDataTeam={poaDataTeam}
                          poaDataLeague={poaDataLeague}
                          yAxisKey={distroYAxis}
                          colorAxisKey={distroColoring}
                        />
                        {displayKey && (
                          <RunKey
                            isVertical={false}
                            colorAxisSetup={RUN_TENDENCY_AXES[distroColoring]}
                            linesMetricName={poaLinesMetricName}
                          />
                        )}
                      </ExportModal>
                    </div>
                  </SummaryTileHeader>
                  <SummaryTileBody $padding="0 1rem 1rem" $minHeight="auto">
                    {isError && (
                      <Message negative>
                        There has been an error. Please contact support.
                      </Message>
                    )}
                    <FilterRow
                      playersLists={playersLists}
                      selectedPlayers={selectedPlayers}
                      setSelectedPlayers={setSelectedPlayers}
                    />
                    <FilterRow
                      playersLists={playersLists}
                      selectedPlayers={selectedPlayers}
                      setSelectedPlayers={setSelectedPlayers}
                      isOffensiveLine={false}
                    />
                    {!displayTable && (
                      <div>
                        {isLoading && (
                          <Dimmer active style={{ opacity: 0.4 }}>
                            <Loader content="Loading Data" />
                          </Dimmer>
                        )}
                        <ChartButtonRibbon>
                          <Typography variant="headingMedium">
                            {gapChartTitle}
                          </Typography>
                          <RunTendenciesContainer
                            $horizontal={horizontalKey || !displayKey}
                          >
                            <RunGapScatter
                              runGapData={gapData}
                              runGapDataTeam={gapDataTeam}
                              teamAverageTotals={teamAverageTotals}
                              leagueAverageTotals={leagueAverageTotals}
                              yAxisKey={bubbleYAxis}
                              rAxisKey={bubbleRAxis}
                              colorAxisKey={bubbleColoring}
                              handleBubbleClick={(datum) => {
                                onBubbleClick(datum);
                              }}
                              selectedBubble={
                                selectedPlays?.isBubble
                                  ? selectedPlays?.id
                                  : null
                              }
                            />
                            {displayKey && (
                              <RunKey
                                title="Run Gap Bubbles"
                                scalingAxisSetup={
                                  RUN_TENDENCY_AXES[bubbleRAxis]
                                }
                                colorAxisSetup={
                                  RUN_TENDENCY_AXES[bubbleColoring]
                                }
                                linesMetricName={gapLinesMetricName}
                                isVertical={!horizontalKey}
                              />
                            )}
                          </RunTendenciesContainer>
                        </ChartButtonRibbon>
                        <POAChartTitle $horizontal={horizontalKey}>
                          <Typography variant="headingMedium">
                            {poaChartTitle}
                          </Typography>
                        </POAChartTitle>
                        <RunTendenciesContainer
                          $horizontal={horizontalKey || !displayKey}
                        >
                          <RunPOAHistogram
                            poaData={poaData}
                            poaDataTeam={poaDataTeam}
                            poaDataLeague={poaDataLeague}
                            yAxisKey={distroYAxis}
                            colorAxisKey={distroColoring}
                            handleBarClick={onBarClick}
                            selectedBar={
                              selectedPlays?.isBubble ? null : selectedPlays?.id
                            }
                          />
                          {displayKey && (
                            <RunKey
                              title="Run Point of Attack Bars"
                              colorAxisSetup={RUN_TENDENCY_AXES[distroColoring]}
                              linesMetricName={poaLinesMetricName}
                              isVertical={!horizontalKey}
                            />
                          )}
                        </RunTendenciesContainer>
                      </div>
                    )}
                    {displayTable && (
                      <>
                        <RunTable
                          runData={gapData}
                          teamRunData={gapDataTeam}
                          leagueRunData={gapDataLeague}
                          tableMode={RUN_TENDENCY_TABLE_MODES.GAP.value}
                          tableName="Gap Information"
                          csvName="Run Gaps"
                        />
                        <div style={{ marginTop: '2rem' }}>
                          <RunTable
                            runData={poaData}
                            teamRunData={poaDataTeam}
                            leagueRunData={poaDataLeague}
                            tableMode={RUN_TENDENCY_TABLE_MODES.POA.value}
                            tableName="Point of Attack Information"
                            csvName="Run POA"
                          />
                        </div>
                      </>
                    )}
                  </SummaryTileBody>
                </SummaryTile>
              </div>
              <div>
                <FixedAside
                  $width={`${sidebarWidthCalc + 17}px`}
                  style={{ marginLeft: 0, paddingLeft: 0 }}
                >
                  {videoOpen && (
                    <MemoizedVideoTile
                      title={selectedPlays?.title}
                      subTitle={selectedPlays?.subTitle}
                      data={playsVideo.isLoading ? null : playsVideo?.data}
                      handleClose={() => {
                        setSelectedPlays(null);
                        setVideoOpen(false);
                      }}
                    />
                  )}

                  {!videoOpen && (
                    <>
                      <AccordionTile
                        id="axes-settings-accordion"
                        isExpandedDefault
                        isMount
                        header={
                          <Tile.AccordionHeader>
                            Run Gap Settings
                          </Tile.AccordionHeader>
                        }
                        body={
                          <Tile.AccordionBody>
                            <Dropdown
                              id="bubble-y-axis-dropdown"
                              options={RUN_TENDENCY_AXES_OPTIONS}
                              label="Gap Bubble Y Axis"
                              menuPosition="static"
                              onChange={(selectedOption) =>
                                setBubbleYAxis(selectedOption.value)
                              }
                              value={RUN_TENDENCY_AXES_OPTIONS.find(
                                (f) => f.value === bubbleYAxis
                              )}
                            />
                            <Dropdown
                              id="bubble-r-axis-dropdown"
                              options={RUN_TENDENCY_AXES_OPTIONS}
                              label="Gap Bubble Sizing"
                              menuPosition="static"
                              onChange={(selectedOption) =>
                                setBubbleRAxis(selectedOption.value)
                              }
                              value={RUN_TENDENCY_AXES_OPTIONS.find(
                                (f) => f.value === bubbleRAxis
                              )}
                            />
                            <Dropdown
                              id="bubble-coloring-axis-dropdown"
                              options={RUN_TENDENCY_AXES_OPTIONS}
                              label="Gap Bubble Coloring"
                              menuPosition="static"
                              onChange={(selectedOption) =>
                                setBubbleColoring(selectedOption.value)
                              }
                              value={RUN_TENDENCY_AXES_OPTIONS.find(
                                (f) => f.value === bubbleColoring
                              )}
                            />
                          </Tile.AccordionBody>
                        }
                      />

                      <AccordionTile
                        id="chart-keys-accordion"
                        header={
                          <Tile.AccordionHeader>
                            Point of Attack Settings
                          </Tile.AccordionHeader>
                        }
                        isExpandedDefault
                        isMount
                        body={
                          <Tile.AccordionBody>
                            <Dropdown
                              id="distro-y-axis-dropdown"
                              options={RUN_TENDENCY_AXES_OPTIONS}
                              label="Point of Attack Y Axis"
                              menuPosition="static"
                              onChange={(selectedOption) =>
                                setDistroYAxis(selectedOption.value)
                              }
                              value={RUN_TENDENCY_AXES_OPTIONS.find(
                                (f) => f.value === distroYAxis
                              )}
                            />
                            <Dropdown
                              id="distro-coloring-axis-dropdown"
                              options={RUN_TENDENCY_AXES_OPTIONS}
                              label="Point of Attack Coloring"
                              menuPosition="static"
                              onChange={(selectedOption) =>
                                setDistroColoring(selectedOption.value)
                              }
                              value={RUN_TENDENCY_AXES_OPTIONS.find(
                                (f) => f.value === distroColoring
                              )}
                            />
                          </Tile.AccordionBody>
                        }
                      />
                    </>
                  )}
                </FixedAside>
              </div>
            </SidebarRightLayout>
          </Grid>
        </Grid>
      </KitbagPageGridHolder>
    </div>
  );
};

export default RunTendencies;
