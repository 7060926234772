import React from 'react';
import { Breadcrumb } from 'semantic-ui-react';
import { Grid, Icon } from '@statsbomb/kitbag-components';
import { useTheme } from 'styled-components';
import { useQuery, useReactiveVar } from '@apollo/client';
import { GET_GAME_PLAYS } from './GetGamePlays';
import { mf_Teams } from '../../../apollo';
import { formatPlay } from './PlayByPlay.DataManipulation';
import PlayByPlayAccordion from './PlayByPlayAccordion/PlayByPlayAccordion';
import Tile from '../../../components/Tile/Tile';
import {
  PlayByPlayHeaderContainer,
  PlayByPlayHeader,
} from './PlayByPlay.styles';
import { useDistinctTeamColors } from '../../../utils/hooks/useDistinctTeamColors';
import { KitbagPageGridHolder } from '../../../components/Layout/Layout.styles';

const PlayByPlay = () => {
  // data
  const {
    data: playsData,
    loading: playsLoading,
    error: playsError,
  } = useQuery(GET_GAME_PLAYS);

  const theme = useTheme();
  const visPalette = theme.colours.visualisations;
  const selectedTeam = useReactiveVar(mf_Teams);

  const formattedPlays = playsData?.plays.edges
    .map(formatPlay)
    .filter((p) => p.drive);

  const gameDetails = formattedPlays?.[0]?.game;
  // whether the currently selected team is the home team
  const isHomeTeam = gameDetails?.homeTeam.id === selectedTeam;

  // date
  const formattedDate = new Date(gameDetails?.date).toLocaleString('en-us', {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
  });
  const gameDate = formattedDate === 'Invalid Date' ? '' : formattedDate;

  // names
  const selectedTeamName = isHomeTeam
    ? gameDetails?.homeTeam.name
    : gameDetails?.awayTeam.name;
  const opponentTeamName = isHomeTeam
    ? gameDetails?.awayTeam.name
    : gameDetails?.homeTeam.name;

  // scores
  const selectedTeamScore = isHomeTeam
    ? gameDetails?.homeScore
    : gameDetails?.awayScore;
  const opponentTeamScore = isHomeTeam
    ? gameDetails?.awayScore
    : gameDetails?.homeScore;

  // colors
  const homeTeamColors = {
    primary: gameDetails?.homeTeam.colorPrimary,
    secondary: gameDetails?.homeTeam.colorSecondary,
    tertiary: gameDetails?.homeTeam.colorTertiary,
  };
  const awayTeamColors = {
    primary: gameDetails?.awayTeam.colorPrimary,
    secondary: gameDetails?.awayTeam.colorSecondary,
    tertiary: gameDetails?.awayTeam.colorTertiary,
  };
  const selectedTeamColors = isHomeTeam ? homeTeamColors : awayTeamColors;
  const opponentTeamColors = isHomeTeam ? awayTeamColors : homeTeamColors;

  const { selectedTeamColor, opponentTeamColor } = useDistinctTeamColors(
    selectedTeamColors,
    opponentTeamColors
  );

  const validScores =
    typeof selectedTeamScore === 'number' &&
    typeof opponentTeamScore === 'number';

  return (
    <KitbagPageGridHolder>
      <Grid container={false} page>
        <Grid item xs={12}>
          <Tile border="0" margin="0">
            <PlayByPlayHeaderContainer>
              <Breadcrumb size="huge">
                <Breadcrumb.Section>Game</Breadcrumb.Section>
                <Breadcrumb.Divider />
                <Breadcrumb.Section active>
                  Play by Play Feed
                </Breadcrumb.Section>
              </Breadcrumb>
              <PlayByPlayHeader
                $selectedTeamColor={selectedTeamColor}
                $opponentTeamColor={opponentTeamColor}
              >
                <span>{gameDate}</span>
                <span>{selectedTeamName || 'Selected Team'}</span>
                <Icon variant="HalfMoonLeft" size="tiny" />
                {` ${isHomeTeam ? 'vs' : '@'} `}
                <Icon variant="HalfMoonTop" size="tiny" />
                <span>{`${opponentTeamName || 'Opponent Team'} ${
                  validScores ? ',' : ''
                }`}</span>
                {validScores && (
                  <>
                    <span>{selectedTeamScore}</span>
                    <span>-</span>
                    <span>{opponentTeamScore}</span>
                  </>
                )}
              </PlayByPlayHeader>
            </PlayByPlayHeaderContainer>
            <PlayByPlayAccordion
              playsData={formattedPlays}
              isLoading={playsLoading}
              isError={!!playsError}
              selectedTeamColor={selectedTeamColor}
              opponentTeamColor={opponentTeamColor}
              visPalette={visPalette}
            />
          </Tile>
        </Grid>
      </Grid>
    </KitbagPageGridHolder>
  );
};

export default PlayByPlay;
