import { styled } from 'styled-components';

export const AccordionWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  border-color: ${({ theme }) => theme.colours.canvas.primary.main};
  // We should have a min width, as anything smaller will look strange
  min-width: 15rem;
  min-height: 10rem;

  & > div:nth-child(odd) {
    background-color: ${({ theme }) => theme.colours.canvas.secondary.main};

    li:not(:last-of-type) {
      border-bottom: 1px solid
        ${({ theme }) => theme.colours.canvas.primary.main};
    }
  }

  & > div:nth-child(odd) button:first-child {
    &:hover {
      background-color: ${({ theme }) => theme.colours.canvas.secondary.strong};
    }
  }

  & > div:nth-child(even) {
    background: ${({ theme }) => theme.colours.canvas.tertiary.main};

    li:last-of-type {
      border-bottom: transparent;
    }

    li:not(:last-of-type) {
      border-bottom: 1px solid
        ${({ theme }) => theme.colours.canvas.primary.main};
    }
  }

  & > div:nth-child(even) button:first-child {
    &:hover {
      background-color: ${({ theme }) => theme.colours.canvas.tertiary.strong};
    }
  }
`;

// TO DO: Mobile version of this
export const DriveHeader = styled.div`
  width: 100%;
  min-width: 700px;
  height: 2.4rem;
  ${({ theme }) => theme.typography.headingSmall}
  display: flex;
  align-items: center;
  padding: 0 5rem 0 1.5rem;
  border-bottom: 2px solid ${({ theme }) => theme.colours.interface.main};
  color: ${({ theme }) => theme.colours.graphics.primary.main};
  background-color: ${({ theme }) => theme.colours.canvas.tertiary.main};
  position: relative;

  .drive-index {
    width: 7.5%;
  }

  .drive-clock {
    width: 10%;
  }

  .drive-team-on-offense {
    width: 25%;
  }

  .drive-result {
    width: 20%;
  }

  .team-scores {
    flex: 1;
    display: flex;
    justify-content: center;
    gap: 2rem;

    & > div {
      width: 45%;
    }
  }

  .team-separator {
    width: 2rem;
    position: absolute;
  }
`;

export const TeamHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  position: relative;
  justify-content: ${({ $isSelectedTeam }) =>
    $isSelectedTeam ? 'end' : 'start'};

  svg {
    min-height: 1.4rem;
    min-width: 1.4rem;

    path {
      fill: ${({ $color }) => $color};
    }
  }

  .team-container {
    position: relative;

    .underline {
      position: absolute;
      width: calc(100% + 1.5rem);
      border-bottom: 2px solid ${({ $color }) => $color};
      bottom: -0.6rem;
      left: ${({ $isSelectedTeam }) => $isSelectedTeam && '0'};
      right: ${({ $isSelectedTeam }) => !$isSelectedTeam && '0'};
    }
  }

  .team-name {
    white-space: nowrap;

    &::before {
      content: '';
      position: absolute;
      border-bottom: 2px solid
        ${({ theme }) => theme.colours.canvas.secondary.main};
      z-index: 2;
      top: 1.8rem;
      width: 0.5rem;
      left: ${({ $isSelectedTeam }) => ($isSelectedTeam ? '-0.5rem' : '-2rem')};
    }

    &::after {
      content: '';
      position: absolute;
      border-bottom: 2px solid
        ${({ theme }) => theme.colours.canvas.secondary.main};
      z-index: 2;
      top: 1.8rem;
      width: 0.5rem;
      right: ${({ $isSelectedTeam }) =>
        $isSelectedTeam ? '-2rem' : '-0.5rem'};
    }
  }
`;

AccordionWrapper.displayName = 'AccordionWrapper';
DriveHeader.displayName = 'DriveHeader';
TeamHeader.displayName = 'TeamHeader';
