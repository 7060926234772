import React from 'react';
import { StyledBadge } from './sideNav.styles';
import { Badge } from '@statsbomb/kitbag-components';

const BadgeLabel = ({ condition, text }) =>
  condition ? (
    <StyledBadge>
      <Badge>{text}</Badge>
    </StyledBadge>
  ) : (
    <></>
  );

export default BadgeLabel;
