import { styled } from 'styled-components';
import { slideAnimationStyle } from '../../styles/global.styles';
import { SIDEBAR_WIDTH } from '../SideNavigation/sideNav.styles';
import { HEADER_HEIGHT } from '../Header/Header.styles';

const FILTER_SECTION_HEIGHT = '100px';

export const StyledLayout = styled.div`
  height: 100vh;
  ${({ $withFilters }) =>
    $withFilters && `padding-top: ${FILTER_SECTION_HEIGHT}`};
  background-color: ${({ theme }) => theme.colours.canvas.primary.main};

  .core {
    z-index: 90;
    display: flex;
    ${({ $withFilters }) =>
      $withFilters && `height: calc(100vh - ${FILTER_SECTION_HEIGHT})`};
  }

  .cover {
    z-index: 102;
    position: absolute;
    height: 100vh;
    width: 100vw;
    background-color: ${({ theme }) => theme.colours.canvas.primary.main};
    left: ${SIDEBAR_WIDTH};
    top: ${HEADER_HEIGHT};
    padding: 30px;
  }
`;
StyledLayout.displayName = 'StyledLayout';

export const BasicPage = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
`;
BasicPage.displayName = 'BasicPage';

export const FixedAside = styled.aside`
  position: relative;
  height: 100vh;
  padding: ${({ theme }) => `${theme.sizing.small} 0 6.9rem 0`};
  margin: ${({ theme }) => `-${theme.sizing.small} 0 0 0`};
  overflow-y: auto;
  box-shadow: none;
  width: ${({ $width }) => $width || 'inherit'};
  scrollbar-gutter: stable;

  ${({ theme }) => theme.mqFrom('md')} {
    position: fixed;
    padding-top: ${({ theme, $paddingTopMD }) =>
      $paddingTopMD || theme.sizing.small};
  }
`;
FixedAside.displayName = 'FixedAside';

export const Main = styled.main`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  background-color: ${({ theme }) => theme.colours.canvas.primary.main};
  overflow: auto;
`;
Main.displayName = 'Main';

// (RH) sidebar layout
// based on immediate two child <div>
// sidebar width defaults to 200px
export const SidebarRightLayout = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ $gap }) => $gap || '1rem'};
  width: 100%;
  padding: ${({ $padding }) => $padding || '0 0.5rem'};

  > :last-child {
    flex-basis: ${({ $sidebarWidth }) => $sidebarWidth || '200px'};
    transition: ${`flex-basis 500ms ${slideAnimationStyle}`};
    flex-grow: ${({ $closed }) => $closed || 1};
  }

  > :first-child {
    flex-basis: 0;
    flex-grow: 999;
    min-inline-size: ${({ $inlineSize }) => $inlineSize || '60%'};
  }
`;
SidebarRightLayout.displayName = 'SidebarRightLayout';

/*
This is removing the standard padding from Main in the layout so the grid component page can be used instead
Once the grid is implemented universally it can be dropped
 */
export const BasicPageNoPadding = styled.div`
  position: absolute;
  top: ${({ $top }) => $top || '98px'}; /* visible height of the top bar */
  left: 0;
  ${({ theme }) => theme.mqFrom('md')} {
    left: ${({ $left }) =>
      $left || `${SIDEBAR_WIDTH}`}; /* visible width of the sidebar */
  }
  margin: 0;
  padding: 0;
  width: 100%;
  ${({ theme }) => theme.mqFrom('md')} {
    width: calc(100% - ${SIDEBAR_WIDTH});
  }
`;
BasicPageNoPadding.displayName = 'BasicPageNoPadding';

/*
Div holding the Grid used to override default margin-top so layout is correct
 */
export const KitbagPageGridHolder = styled.div`
  padding: 0;
  margin: 0;
  width: 100%;

  /*the <Grid> component */
  & > div:first-child {
    margin-top: 0;
    margin-bottom: ${({ theme }) => `${theme.sizing.regular}`};
  }
`;
KitbagPageGridHolder.displayName = 'KitbagPageGridHolder';
