import React, { useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { CSVLink } from 'react-csv';
import { Breadcrumb, Loader } from 'semantic-ui-react';
import {
  Button,
  ButtonIcon,
  ButtonGroup,
  Dropdown,
  Tooltip,
  Icon,
  Grid,
} from '@statsbomb/kitbag-components';
import { orderBy } from 'lodash';
import useQueryString from '../../../utils/hooks/useQueryString';
import Dimmer from '../../../components/Dimmer/Dimmer';
import { ALL_PACKAGES_OPTION } from './TeamDepthChart.constants';
import { mf_TeamDetails } from '../../../apollo';
import {
  DepthChartHeader,
  DepthChartTitle,
  DepthChartTablesContainer,
  PersonnelDistributionHeader,
  PersonnelDistribution,
  PersonnelDistributionBars,
  ScaleIndicator,
  StyledToolTip,
} from './TeamDepthChart.styles';
import Tile from '../../../components/Tile/Tile';
import DepthChartDrawer from './DepthChartDrawer';
import DepthChartTable from './DepthChartTable';
import PersonnelDistributionBar from './PersonnelDistributionBar';
import {
  getPackageOptions,
  getPlayerCsvData,
  getTeamCsvData,
  formatTeamData,
} from './TeamDepthChart.dataManipulation';
import { SORT_DIRECTIONS } from '../../../utils/constants/sortDirections';
import {
  useDepthChartPlayers,
  useDepthChartRadars,
  useDepthChartTeam,
} from './TeamDepthChart.hooks';
import { ALIGNMENT_POSITIONS } from '../../../utils/constants/positions';
import { escapeDownloadString } from '../../../utils/helpers/strings';
import { useExportSeasonInfo } from '../../../utils/hooks/useExportSeasonInfo';
import {
  KitbagPageGridHolder,
  SidebarRightLayout,
} from '../../../components/Layout/Layout.styles';

const TeamDepthChart = () => {
  const selectedTeam = useReactiveVar(mf_TeamDetails);
  const seasonInfo = useExportSeasonInfo();
  // local state
  const [offense, setOffense] = useQueryString('offense', true);
  const [groupByAlignment, setGroupByAlignment] = useQueryString(
    'alignment',
    false
  );
  const [selectedPackage, setSelectedPackage] = useQueryString(
    'package',
    ALL_PACKAGES_OPTION.value
  );
  const [templatePosition, setTemplatePosition] = useState('');
  const [playerIds, setPlayerIds] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  const teamType = offense ? 'Offense' : 'Defense';

  // the data that drives the personnel distribution section
  const { depthChartTeamData, depthChartTeamLoading, depthChartTeamError } =
    useDepthChartTeam(offense, teamType);

  // the data that drives the depth chart tables section
  const {
    data: depthChartPlayersData,
    loading: depthChartPlayersLoading,
    error: depthChartPlayersError,
  } = useDepthChartPlayers(offense, groupByAlignment, selectedPackage);

  // the data that drives the contents of the radar drawer
  const { templateConfig, radarsData, radarsLoading, radarsError } =
    useDepthChartRadars(templatePosition, playerIds);

  const isPageLoading = depthChartPlayersLoading || depthChartTeamLoading;
  const teamTotalSnaps = depthChartTeamData?.snapCount;
  const personnelDistribution = formatTeamData(depthChartTeamData?.personnel);

  const orderedTables = orderBy(
    depthChartPlayersData,
    ['order'],
    [SORT_DIRECTIONS.ASCENDING]
  );
  const packageOptions = getPackageOptions(depthChartTeamData?.personnel);
  const selectedOption = packageOptions.find(
    (option) => option.value === selectedPackage
  );

  // csv downloads
  const csvPlayerData = getPlayerCsvData(orderedTables);
  const positionType = groupByAlignment ? 'Alignment' : 'Roster';
  const playerCsvFileName = `Depth Chart-${teamType}-${selectedOption?.label}-${positionType}`;
  const csvTeamData = getTeamCsvData(personnelDistribution);
  const teamCsvFileName = `${selectedTeam?.name} - ${teamType} - ${selectedOption?.label} - Distribution`;
  const exportTitle = `${selectedTeam?.name} Depth Chart`;
  const exportSubHeading = ALIGNMENT_POSITIONS[templatePosition]?.name;
  const exportFileName = `${selectedTeam?.name}-${ALIGNMENT_POSITIONS[templatePosition]?.name}-Radars`;

  const handleOffense = (isOffense) => {
    if (isOffense !== offense) {
      setOffense(isOffense);
      setSelectedPackage(ALL_PACKAGES_OPTION.value);
    }
  };

  const handleAlignment = (isAlignment) => {
    if (isAlignment !== groupByAlignment) {
      setGroupByAlignment(isAlignment);
    }
  };

  const handlePackageChange = (option) => {
    if (option.value !== selectedPackage) {
      setSelectedPackage(option.value);
    }
  };

  // open the drawer trigger its radar data fetching
  const handleClick = (position, tableData) => {
    const ids = tableData.map((player) => player.id);
    setIsOpen(true);
    setTemplatePosition(position);
    setPlayerIds(ids);
  };

  // close the drawer and reset its contents
  const onBackdropClick = () => {
    setIsOpen(false);
    setTemplatePosition('');
    setPlayerIds([]);
  };

  const getFeedback = (data, loading, error, isPlayersData) => {
    if (loading) {
      return <Loader inverted content="Loading" />;
    }
    if (error) {
      return 'Data error';
    }
    if (!data?.length) {
      return `No data available${
        isPlayersData ? ', no players meet the 10 plays minimum threshold.' : ''
      }`;
    }
    return null;
  };
  const tablesFeedback = getFeedback(
    orderedTables,
    depthChartPlayersLoading,
    depthChartPlayersError,
    true
  );
  const personnelFeedback = getFeedback(
    depthChartTeamData?.personnel,
    depthChartTeamLoading,
    depthChartTeamError
  );
  const drawerFeedback = getFeedback(radarsData, radarsLoading, radarsError);

  return (
    <KitbagPageGridHolder>
      <Grid container={false} page>
        <Grid item xs={12}>
          <SidebarRightLayout
            $sidebarWidth="500px"
            $gap="0.5rem"
            $padding="0 0.25rem 0 0"
          >
            <div>
              <Tile margin="0">
                <Tile.Header>
                  <DepthChartHeader>
                    <DepthChartTitle>
                      <Breadcrumb size="huge">
                        <Breadcrumb.Section>Team</Breadcrumb.Section>
                        <Breadcrumb.Divider />
                        <Breadcrumb.Section active>
                          Depth Chart
                        </Breadcrumb.Section>
                      </Breadcrumb>
                      <CSVLink
                        data={csvPlayerData}
                        filename={playerCsvFileName}
                      >
                        <ButtonIcon
                          size="small"
                          variant="secondary"
                          icon="Download"
                          disabled={isPageLoading}
                          title="Download depth chart tables"
                        >
                          download depth chart tables
                        </ButtonIcon>
                      </CSVLink>
                    </DepthChartTitle>
                    <div className="controls">
                      <ButtonGroup>
                        <Button
                          size="small"
                          onClick={() => handleOffense(true)}
                          variant={offense ? 'primary' : 'ghost'}
                        >
                          Offense
                        </Button>
                        <Button
                          size="small"
                          onClick={() => handleOffense(false)}
                          variant={offense ? 'ghost' : 'primary'}
                        >
                          Defense
                        </Button>
                      </ButtonGroup>
                      <div className="dropdown">
                        <Dropdown
                          id="depth-chart-dropdown"
                          label="plays"
                          labelPosition="none"
                          size="small"
                          options={packageOptions}
                          value={selectedOption}
                          onChange={handlePackageChange}
                          variant="primary"
                          isSearchable={false}
                          isDisabled={isPageLoading}
                          isLoading={isPageLoading}
                        />
                      </div>
                      <div className="divider" />
                      <ButtonGroup>
                        <Button
                          size="small"
                          onClick={() => handleAlignment(false)}
                          variant={groupByAlignment ? 'ghost' : 'primary'}
                        >
                          Roster
                        </Button>
                        <Button
                          size="small"
                          onClick={() => handleAlignment(true)}
                          variant={groupByAlignment ? 'primary' : 'ghost'}
                        >
                          Alignment
                        </Button>
                      </ButtonGroup>
                      <Tooltip
                        content={
                          <StyledToolTip>
                            Only players with <u>10 or more plays</u> will be
                            shown in the depth chart below.
                          </StyledToolTip>
                        }
                        delay={200}
                        isCondensed
                      >
                        <Icon
                          variant="Info"
                          size="small"
                          colour="primary.main"
                        />
                      </Tooltip>
                      <p>Minimum 10 plays</p>
                    </div>
                  </DepthChartHeader>
                </Tile.Header>
                <DepthChartTablesContainer
                  $numberOfTables={orderedTables.length}
                >
                  {tablesFeedback && (
                    <Dimmer active inverted>
                      {tablesFeedback}
                    </Dimmer>
                  )}
                  {!isPageLoading &&
                    orderedTables.map((table) => (
                      <DepthChartTable
                        numberOfTables={orderedTables.length}
                        key={table.position}
                        data={table.data}
                        positionApiKey={table.position}
                        handleClick={() =>
                          handleClick(table.position, table.data)
                        }
                      />
                    ))}
                </DepthChartTablesContainer>
              </Tile>
            </div>
            <div>
              <Tile margin="0">
                <Tile.Header>
                  <PersonnelDistributionHeader>
                    <DepthChartTitle>
                      <h2>Personnel Distribution</h2>
                      <CSVLink
                        data={csvTeamData}
                        filename={escapeDownloadString(teamCsvFileName)}
                      >
                        <ButtonIcon
                          size="small"
                          variant="secondary"
                          icon="Download"
                          disabled={isPageLoading}
                          title="Download personnel distribution"
                        >
                          download personnel distribution
                        </ButtonIcon>
                      </CSVLink>
                    </DepthChartTitle>
                    <div className="details">
                      <div>
                        <div className="team">Play % [Plays]</div>
                        <div className="league">
                          <div className="indicator" />
                          <div>League average</div>
                        </div>
                      </div>
                    </div>
                  </PersonnelDistributionHeader>
                </Tile.Header>
                <PersonnelDistribution>
                  {personnelFeedback && (
                    <Dimmer active inverted>
                      {personnelFeedback}
                    </Dimmer>
                  )}
                  <ScaleIndicator data-max={offense ? '70%' : '50%'} />
                  <PersonnelDistributionBars>
                    {personnelDistribution.map((p) => (
                      <PersonnelDistributionBar
                        key={p.label}
                        snapCount={p.snapCount}
                        snapRate={p.snapRate}
                        leagueAverage={p.competitionRate}
                        label={p.label}
                        visibleMax={offense ? 0.7 : 0.5}
                      />
                    ))}
                  </PersonnelDistributionBars>
                </PersonnelDistribution>
              </Tile>
              <DepthChartDrawer
                isOpen={isOpen}
                positionApiKey={templatePosition}
                onBackdropClick={onBackdropClick}
                radarsData={selectedTeam && radarsData ? radarsData : []}
                drawerFeedback={drawerFeedback}
                teamTotalSnaps={teamTotalSnaps}
                templateConfig={templateConfig}
                exportTitle={exportTitle}
                exportSubHeading={exportSubHeading}
                exportInfo1={seasonInfo}
                exportInfo2={`By ${positionType} Position`}
                exportFileName={escapeDownloadString(
                  exportFileName.replace(/\s/g, '-')
                )}
              />
            </div>
          </SidebarRightLayout>
        </Grid>
      </Grid>
    </KitbagPageGridHolder>
  );
};

export default TeamDepthChart;
