import { styled } from 'styled-components';

export const PageHeaderTile = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colours.canvas.tertiary.main};
  height: 3rem;
  overflow: hidden;
  position: relative;
`;
PageHeaderTile.displayName = 'PageHeaderTile';

export const InfoBar = styled.div`
  display: flex;
  height: 3rem;
  align-items: center;
  padding: 0.5rem 1rem;
  ${({ theme }) => theme.typography.headingLarge}

  div {
    flex: 1;
    margin: 0;
    padding: 0;

    .ui.dropdown.clear .icon {
      font-size: 1rem;
      margin: 0;
    }

    .ui.breadcrumb {
      color: ${({ theme }) => theme.colours.ink.primary.main};

      &:first-child {
        margin-top: 0;
      }
    }
  }

  div + div {
    text-align: center;

    h3 {
      margin-top: 0;
    }
  }

  div:last-child {
    text-align: right;

    .buttons {
      display: inline-flex;
      gap: 0.5rem;
    }
  }

  .ui.simple.dropdown {
    padding: 0;

    .menu {
      right: 0;
      left: auto;
      background: ${({ theme }) => theme.colours.canvas.secondary.main};

      > .item {
        font-size: 1.35rem;
        padding: 0.625rem 0.9375rem !important;
        color: ${({ theme }) => theme.colours.ink.primary.main};

        &:hover {
          background: ${({ theme }) => theme.colours.canvas.tertiary.main};
          color: ${({ theme }) => theme.colours.interface.main} !important;
        }
      }
    }
  }
`;
InfoBar.displayName = 'InfoBar';
