import React from 'react';
import { Menu, MenuItem, MenuItemText } from '@statsbomb/kitbag-components';
import { useKitbagAuth } from '@statsbomb/kitbag-auth';
import ParsedLink from '../ParsedLink/ParsedLink';
import BadgeLabel from './BadgeLabel';
import { isPageSelected } from './SideNav.config';

const SubMenu = ({ menuItems, currentLocation, isMobile }) => {
  const { user, logout } = useKitbagAuth();
  const LogOut = () => {
    sessionStorage.removeItem('uiStateLocal');
    logout();
  };
  return (
    <Menu variant="subnavigation">
      {menuItems.map((menuItem) => (
        <ParsedLink href={menuItem.href}>
          <MenuItem
            key={menuItem.id}
            size={isMobile ? 'touch' : 'small'}
            isSelected={isPageSelected(currentLocation, menuItem.id)}
            variant="subnavigation"
            opacity="secondary"
            onClick={() => (menuItem.name === 'Logout' ? LogOut() : undefined)}
          >
            <MenuItemText isTouch={isMobile}>
              {menuItem.name}
              <BadgeLabel
                condition={
                  menuItem.userGroup === user['https://statsbomb.com/accountID']
                }
                text="WIP"
              />
              <BadgeLabel condition={menuItem?.inBeta} text="BETA" />
            </MenuItemText>
          </MenuItem>
        </ParsedLink>
      ))}
    </Menu>
  );
};

export default SubMenu;
