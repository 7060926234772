import { styled } from 'styled-components';

export const PlayByPlayHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.colours.canvas.tertiary.main};
  color: ${({ theme }) => theme.colours.ink.primary.main};
  padding: 1rem 2rem;

  .active.section {
    font-size: 1.8rem;
    font-weight: 700;
  }
`;

export const PlayByPlayHeader = styled.h1`
  display: flex;
  align-items: center;
  ${({ theme }) => theme.typography.headingRegular};
  gap: ${({ theme }) => theme.sizing.small};

  span:first-of-type {
    margin-right: 1.5rem;
  }

  svg {
    min-height: 1.5rem;
    min-width: 1.5rem;
  }

  svg:nth-of-type(1) {
    path {
      fill: ${({ $selectedTeamColor }) => $selectedTeamColor};
    }
  }

  svg:nth-of-type(2) {
    path {
      fill: ${({ $opponentTeamColor }) => $opponentTeamColor};
    }
  }
`;
