import React, { useState } from 'react';
import { Breadcrumb, Loader } from 'semantic-ui-react';
import { useReactiveVar } from '@apollo/client';
import { sortBy } from 'lodash';
import { Dropdown, Grid, Icon, Toggle } from '@statsbomb/kitbag-components';
import Dimmer from '../../../components/Dimmer/Dimmer';
import useQueryString from '../../../utils/hooks/useQueryString';
import Tile from '../../../components/Tile/Tile';
import { mf_Leagues, mf_TeamDetails } from '../../../apollo';
import AccordionTile from '../../../components/Accordion/AccordionTile';
import { ROTATIONS, ROTATION_OPTIONS } from '../../../utils/constants/charting';
import TackleLocation from '../../../visualisations/TackleLocation/TackleLocation';
import {
  TACKLE_LOCATION_COLOR_MODES,
  TACKLE_LOCATION_COLOR_MODE_DX,
  TACKLE_LOCATION_DISTRO_COLOR_MODES,
  TACKLE_LOCATION_DISTRO_COLOR_WINNER,
  TACKLING_TACKLER_MODES,
  TACKLING_TACKLER_ANY,
  TACKLE_POSITION_ANY,
  TACKLING_FIELD_DISPLAY_MODES,
  TACKLING_FIELD_DISPLAY_HEATMAP,
  TACKLING_FIELD_DISPLAY_PATHS,
  TACKLING_FIELD_DISPLAY_PLAY_LOCATIONS,
  TACKLING_COORDINATE_FIELD,
  TACKLING_COORDINATE_MODES,
  TACKLING_PLAY_TYPE_RUN,
  TACKLING_PLAY_TYPES,
  TACKLING_PLAY_TYPE_PASS,
  TACKLING_PLAY_TYPE_SACK,
  TACKLE_LOCATION_COLOR_MODE_BALL_CARRIER,
  TACKLE_RESULT_ALL,
} from '../../../visualisations/TackleLocation/TackleLocation.constants';
import TableTile from '../../../components/TableTile/TableTile';
import {
  addTackleDerivedValues,
  getBallCarriers,
  getPlayTableData,
  getSelectablePlayerList,
  getTacklers,
  getTacklerPositions,
  getPlayerTacklePlays,
  splitCoordinateMode,
  TACKLE_TABLE_HEADERS,
  getTacklersFooter,
  getBallCarriersFooter,
} from '../../../visualisations/TackleLocation/TackleLocation.dataManipulation';
import KeyButton from '../../../components/buttons/DisplayKey/DisplayKey';
import ExportModal from '../../../components/ExportModal/ExportModal';
import { useExportSeasonInfo } from '../../../utils/hooks/useExportSeasonInfo';
import {
  getTacklingLeagueAverages,
  useGetPlayerStats,
  useGetTacklingPlays,
} from './TeamTackleAttempts.hooks';
import {
  FixedAside,
  KitbagPageGridHolder,
  SidebarRightLayout,
} from '../../../components/Layout/Layout.styles';
import TacklingPlayerTable from './TacklingPlayerTable/TacklingPlayerTable';
import Tooltip from '../../../components/Tooltip/Tooltip';

const TeamTackleAttempts = () => {
  const team = useReactiveVar(mf_TeamDetails);
  const teamName = team?.name;
  const teamId = team?.id;
  const competitionId = useReactiveVar(mf_Leagues);

  /* Filtering Options */
  const [showDefensive, setShowDefensive] = useQueryString('defensive', true);
  const [playType, setPlayType] = useQueryString(
    'playType',
    TACKLING_PLAY_TYPE_RUN.value
  );
  const [displayXFocusedField, setDisplayXFocusedField] = useQueryString(
    'xFocused',
    true
  );
  const [scaleR, setScaleR] = useQueryString('scaleR', true);
  const [selectedRotation, setSelectedRotation] = useQueryString(
    'rotation',
    ROTATIONS.VERTICAL_DOWN
  );
  const [fieldMode, setFieldMode] = useQueryString(
    'fieldMode',
    TACKLING_FIELD_DISPLAY_PLAY_LOCATIONS.value
  );
  /* In [not path] mode: first TA or final ball location as main focus */
  const [fieldFocusOrigin, setFieldFocusOrigin] = useQueryString(
    'fieldFocusOrigin',
    true
  );
  const [showHeatmapDots, setShowHeatmapDots] = useQueryString(
    'showHeatmapDots',
    false
  );
  const [showFirstTackleTails, setShowFirstTackleTails] = useQueryString(
    'showFirstTackleTails',
    false
  );
  const [coordinateMode, setCoordinateMode] = useQueryString(
    'coordinateMode',
    TACKLING_COORDINATE_FIELD.value
  );
  const { displayXMode, displayYMode } = splitCoordinateMode(coordinateMode);
  const [colorMode, setColorMode] = useQueryString(
    'color',
    TACKLE_LOCATION_COLOR_MODE_DX.value
  );
  const [distroAreaColorMode, setDistroAreaColorMode] = useQueryString(
    'distroAreaColor',
    TACKLE_LOCATION_DISTRO_COLOR_WINNER.value
  );
  const [tacklerMode, setTacklerMode] = useQueryString(
    'tacklerMode',
    TACKLING_TACKLER_ANY.value
  );
  const [selectedPlayerId, setSelectedPlayerId] = useQueryString('player', 0);
  const [tackleResult, setTackleResult] = useQueryString(
    'result',
    TACKLE_RESULT_ALL.value
  );
  const [displayKey, setDisplayKey] = useQueryString('key', true);
  const [selectedPlay, setSelectedPlay] = useState(null);
  const [selectedTacklerPosition, setSelectedTacklerPosition] = useQueryString(
    'tacklerPosition',
    TACKLE_POSITION_ANY.value
  );

  /* Data */
  const {
    loading: tacklingLoading,
    error: tacklingError,
    tacklingData,
  } = useGetTacklingPlays(showDefensive, playType);

  const tacklesAndAttempts = tacklingData?.length
    ? addTackleDerivedValues(tacklingData)
    : [];
  const {
    loading: playerStatsLoading,
    error: playerStatsError,
    playerStatsData,
  } = useGetPlayerStats(teamId, playType, showDefensive);

  const isError = !!tacklingError || !!playerStatsError;
  const loading = tacklingLoading || playerStatsLoading;

  /* League Average Data is coming from a CSV until API Built */
  // const tackleAttemptLeagueAverageRaw = tackleAttemptLeagueAverageCSV.filter(
  //   (f) => f.competition_id === competitionId
  // );
  const tackleAttemptLeagueAverage = getTacklingLeagueAverages(
    competitionId,
    playType
  );

  const ballCarriers =
    tacklesAndAttempts && playerStatsData && !showDefensive
      ? getBallCarriers(tacklesAndAttempts, playerStatsData)
      : null;
  const tacklers =
    tacklesAndAttempts && playerStatsData && showDefensive
      ? getTacklers(tacklesAndAttempts, playerStatsData)
      : null;
  const tacklePositions = getTacklerPositions(tacklers);
  const relevantPlayers = showDefensive ? tacklers : ballCarriers;
  const playerOptions = getSelectablePlayerList(
    sortBy(relevantPlayers, 'playerName')
  );
  const playersFooter = showDefensive
    ? getTacklersFooter(tacklers, teamName, competitionId, playType)
    : getBallCarriersFooter(ballCarriers, teamName, competitionId, playType);

  const filteredData = getPlayerTacklePlays(
    tacklesAndAttempts,
    showDefensive,
    selectedPlayerId,
    tacklerMode,
    selectedTacklerPosition,
    tackleResult
  );

  const { selectedPlayData, href } = getPlayTableData(
    filteredData,
    selectedPlay
  );

  const relevantPlayersTable = relevantPlayers ? (
    <TacklingPlayerTable
      bodyData={relevantPlayers}
      footerData={playersFooter}
      tileTitle={showDefensive ? 'Tacklers' : 'Ball Carriers'}
      showDefensive={showDefensive}
      error={isError}
      loading={loading}
      fileName={`${teamName} Players Tackling.csv`}
    />
  ) : (
    <></>
  );
  const selectedPlayTable = selectedPlay && (
    <AccordionTile
      isExpandedDefault
      header={
        <Tile.AccordionHeader>Selected Play Details</Tile.AccordionHeader>
      }
      body={
        <Tile.AccordionBody>
          <TableTile
            data={selectedPlayData}
            tileTitle="Selected Play"
            columnHeaders={TACKLE_TABLE_HEADERS}
            showColumnHeader={false}
            error={isError}
            loading={loading}
            fileName={`${teamName} Play Details.csv`}
            linkHref={href}
          />
        </Tile.AccordionBody>
      }
    />
  );

  const title = selectedPlayerId
    ? relevantPlayers?.find((p) => p.playerId === selectedPlayerId)?.playerName
    : teamName;
  const getTeamPlaysName = () => {
    if (playType === TACKLING_PLAY_TYPE_RUN.value) {
      return 'Run Plays';
    }
    if (playType === TACKLING_PLAY_TYPE_PASS.value) {
      return 'Pass Plays';
    }
    if (playType === TACKLING_PLAY_TYPE_SACK.value) {
      return 'Sack Plays';
    }
    return 'Tackling Plays';
  };
  const getPageTitle = () => {
    if (loading) {
      return `${title} Plays Loading`;
    }
    if (selectedPlayerId) {
      return `${title}: ${filteredData?.length} ${
        showDefensive ? 'Tackle Attempts' : 'Carries'
      }`;
    }
    return `${title}: ${tacklingData?.length} ${getTeamPlaysName()} on ${
      showDefensive ? 'Defense' : 'Offense'
    } ${
      filteredData?.length < tacklingData?.length
        ? ` (${filteredData?.length} displayed)`
        : ''
    }`;
  };
  const secondaryTitle = `Tackling: ${
    showDefensive ? 'Team Tackles' : 'Team Carries'
  }`;
  const info1 = useExportSeasonInfo();
  const info2 = `${filteredData?.length} Plays`;
  const info3 = selectedPlayerId ? teamName : null;

  const fieldFocusName = () => {
    if (fieldMode === TACKLING_FIELD_DISPLAY_HEATMAP.value) {
      return fieldFocusOrigin
        ? 'Heatmap of First Tackle Attempt'
        : 'Heatmap of Final Ball Location';
    }
    return fieldFocusOrigin
      ? 'First Tackle Attempt Locations'
      : 'Final Ball Locations';
  };

  const selectedTacklerId =
    selectedPlayerId && showDefensive ? selectedPlayerId : 0;

  /* Horizontal display has problems, not especially helpful so disabled 
    TODO: Make horizontal mode look good (low priority) */
  const verticalRotations = ROTATION_OPTIONS.slice(0, 2);
  const tackleColorModes = showDefensive
    ? TACKLE_LOCATION_COLOR_MODES
    : TACKLE_LOCATION_COLOR_MODES.concat(
        TACKLE_LOCATION_COLOR_MODE_BALL_CARRIER
      );

  // const tackleResultLabel = (
  //   <>
  //     <span>Tackle Success</span>
  //     {(selectedTacklerId !== 0 ||
  //       selectedTacklerPosition !== TACKLE_POSITION_ANY.value) && (
  //       <Tooltip
  //         content={
  //           <p>
  //             Tackle success enabled with &lsquo;Any Player&rsquo; tackler and
  //             &lsquo;Any Position&rsquo; defensive position selection
  //           </p>
  //         }
  //         target={<Icon variant="Info" size="small" colour="primary.main" />}
  //       />
  //     )}
  //   </>
  // );

  const tacklerModeLabel = (
    <>
      <span>Tackler Mode</span>
      {selectedPlayerId === 0 &&
        selectedTacklerPosition === TACKLE_POSITION_ANY.value && (
          <Tooltip
            content={
              <p>
                Tackler mode enabled with specific tackler or defensive position
                selection
              </p>
            }
            target={<Icon variant="Info" size="small" colour="primary.main" />}
          />
        )}
    </>
  );

  return (
    <KitbagPageGridHolder>
      <Grid container={false} page>
        <Grid item xs={12}>
          <SidebarRightLayout
            $sidebarWidth="370px"
            $gap="0.5rem"
            $padding="0 0.25rem 0 0"
          >
            <div>
              <Tile>
                <Tile.Header>
                  <Breadcrumb size="huge">
                    <Breadcrumb.Section>Team</Breadcrumb.Section>
                    <Breadcrumb.Divider />
                    <Breadcrumb.Section active>Tackling</Breadcrumb.Section>
                  </Breadcrumb>
                  <h1>{getPageTitle()}</h1>
                  <div className="buttons">
                    <KeyButton
                      showKey={displayKey}
                      handleShowKey={() => setDisplayKey(!displayKey)}
                    />
                    <ExportModal
                      title={title}
                      secondaryTitle={secondaryTitle}
                      info1={info1}
                      info2={info2}
                      info3={info3}
                      fileName={`${getPageTitle()}`}
                      isDisabled={loading || !filteredData?.length}
                    >
                      <TackleLocation
                        chartId="export-tackling-chart"
                        data={filteredData}
                        dataLA={tackleAttemptLeagueAverage}
                        orientation={selectedRotation}
                        displayXFocusedField={displayXFocusedField}
                        displayXMode={displayXMode}
                        displayYMode={displayYMode}
                        colorMode={colorMode}
                        scaleR={scaleR}
                        selectedPlay={selectedPlay}
                        setSelectedPlay={setSelectedPlay}
                        displayKey={displayKey}
                        distroAreaColorMode={distroAreaColorMode}
                        ballCarriers={ballCarriers}
                        tacklers={tacklers}
                        fieldMode={fieldMode}
                        fieldFocusOrigin={fieldFocusOrigin}
                        showHeatmapDots={showHeatmapDots}
                        showFirstTackleTails={showFirstTackleTails}
                        selectedTacklerId={selectedTacklerId}
                      />
                    </ExportModal>
                  </div>
                </Tile.Header>
                <Tile.Body>
                  {tacklingLoading && (
                    <Dimmer active style={{ minHeight: '30vh' }}>
                      <Loader content="Loading Tackle Data" />
                    </Dimmer>
                  )}
                  {filteredData && tackleAttemptLeagueAverage && (
                    <TackleLocation
                      data={filteredData}
                      dataLA={tackleAttemptLeagueAverage}
                      orientation={selectedRotation}
                      displayXFocusedField={displayXFocusedField}
                      displayXMode={displayXMode}
                      displayYMode={displayYMode}
                      colorMode={colorMode}
                      scaleR={scaleR}
                      selectedPlay={selectedPlay}
                      setSelectedPlay={setSelectedPlay}
                      displayKey={displayKey}
                      distroAreaColorMode={distroAreaColorMode}
                      ballCarriers={ballCarriers}
                      tacklers={tacklers}
                      fieldMode={fieldMode}
                      fieldFocusOrigin={fieldFocusOrigin}
                      showHeatmapDots={showHeatmapDots}
                      showFirstTackleTails={showFirstTackleTails}
                      selectedTacklerId={selectedTacklerId}
                    />
                  )}
                </Tile.Body>
              </Tile>
              {loading && (
                <Dimmer active style={{ minHeight: '10vh' }}>
                  <Loader content="Loading Player Data" />
                </Dimmer>
              )}
              {!loading && relevantPlayersTable}
            </div>
            <div>
              <FixedAside>
                <AccordionTile
                  isExpandedDefault
                  isMount
                  header={
                    <Tile.AccordionHeader>
                      Offense or Defense
                    </Tile.AccordionHeader>
                  }
                  body={
                    <Tile.AccordionBody>
                      <Toggle
                        id="show-defensive-toggle"
                        label={
                          showDefensive
                            ? `${teamName} on defense`
                            : `${teamName} on offense`
                        }
                        onChange={() => {
                          if (showDefensive) {
                            setSelectedRotation(ROTATIONS.VERTICAL_UP);
                          } else {
                            setSelectedRotation(ROTATIONS.VERTICAL_DOWN);
                            if (
                              colorMode ===
                              TACKLE_LOCATION_COLOR_MODE_BALL_CARRIER.value
                            ) {
                              setColorMode(TACKLE_LOCATION_COLOR_MODE_DX.value);
                            }
                          }
                          setSelectedPlayerId(0);
                          setSelectedTacklerPosition(TACKLE_POSITION_ANY.value);
                          setShowDefensive(!showDefensive);
                        }}
                        checked={showDefensive}
                      />
                      <Dropdown
                        id="tackling-play-type-dropdown"
                        options={TACKLING_PLAY_TYPES}
                        label="Play Type"
                        menuPosition="static"
                        onChange={(selectedOption) => {
                          setPlayType(selectedOption.value);
                          setSelectedPlayerId(0);
                          setSelectedTacklerPosition(TACKLE_POSITION_ANY.value);
                        }}
                        value={TACKLING_PLAY_TYPES.find(
                          (f) => f.value === playType
                        )}
                      />
                      <Dropdown
                        id="tackler-dropdown"
                        options={playerOptions}
                        isLoading={loading}
                        label={showDefensive ? 'Tackler' : 'Ball Carrier'}
                        menuPosition="static"
                        onChange={(selectedOption) => {
                          if (selectedOption.value !== 0) {
                            // reset tackle result if player selected
                            setTackleResult(TACKLE_RESULT_ALL.value);
                          }
                          setSelectedPlayerId(
                            parseInt(selectedOption.value, 10)
                          );
                        }}
                        value={playerOptions.find(
                          (f) => f.value === selectedPlayerId
                        )}
                      />
                      {showDefensive && (
                        <Dropdown
                          id="defensive-positions-dropdown"
                          options={tacklePositions}
                          isLoading={loading}
                          label="Defensive Positions"
                          menuPosition="static"
                          onChange={(selectedOption) => {
                            if (
                              selectedOption.value !== TACKLE_POSITION_ANY.value
                            ) {
                              // reset tackle result if player selected
                              setTackleResult(TACKLE_RESULT_ALL.value);
                            }
                            setSelectedTacklerPosition(selectedOption.value);
                          }}
                          value={tacklePositions.find(
                            (f) => f.value === selectedTacklerPosition
                          )}
                        />
                      )}
                      {/* <Dropdown
                      id="tackle-result-dropdown"
                      options={TACKLE_RESULT_OPTIONS}
                      isLoading={loading}
                      label={tackleResultLabel}
                      menuPosition="static"
                      onChange={(selectedOption) => {
                        setTackleResult(selectedOption.value);
                      }}
                      value={TACKLE_RESULT_OPTIONS.find(
                        (f) => f.value === tackleResult
                      )}
                      isDisabled={
                        selectedTacklerId !== 0 ||
                        selectedTacklerPosition !== TACKLE_POSITION_ANY.value
                      }
                    /> */}
                      {showDefensive && (
                        <Dropdown
                          id="tackler-mode-dropdown"
                          options={TACKLING_TACKLER_MODES}
                          label={tacklerModeLabel}
                          menuPosition="static"
                          onChange={(selectedOption) =>
                            setTacklerMode(selectedOption.value)
                          }
                          value={TACKLING_TACKLER_MODES.find(
                            (f) => f.value === tacklerMode
                          )}
                          isDisabled={
                            selectedPlayerId === 0 &&
                            selectedTacklerPosition ===
                              TACKLE_POSITION_ANY.value
                          }
                        />
                      )}
                    </Tile.AccordionBody>
                  }
                />
                <AccordionTile
                  isExpandedDefault={false}
                  header={
                    <Tile.AccordionHeader>
                      Distribution Display
                    </Tile.AccordionHeader>
                  }
                  body={
                    <Tile.AccordionBody>
                      <Dropdown
                        id="area-coloring-dropdown"
                        options={TACKLE_LOCATION_DISTRO_COLOR_MODES}
                        label="Area Coloring"
                        menuPosition="static"
                        onChange={(selectedOption) =>
                          setDistroAreaColorMode(selectedOption.value)
                        }
                        value={TACKLE_LOCATION_DISTRO_COLOR_MODES.find(
                          (f) => f.value === distroAreaColorMode
                        )}
                      />
                    </Tile.AccordionBody>
                  }
                />
                <AccordionTile
                  isExpandedDefault
                  header={
                    <Tile.AccordionHeader>Field Display</Tile.AccordionHeader>
                  }
                  body={
                    <Tile.AccordionBody>
                      <Dropdown
                        id="coordinates-dropdown"
                        options={TACKLING_COORDINATE_MODES}
                        label="Coordinates"
                        menuPosition="static"
                        onChange={(selectedOption) =>
                          setCoordinateMode(selectedOption.value)
                        }
                        value={TACKLING_COORDINATE_MODES.find(
                          (f) => f.value === coordinateMode
                        )}
                      />
                      <Dropdown
                        id="field-display-dropdown"
                        options={TACKLING_FIELD_DISPLAY_MODES}
                        label="Field Display Mode"
                        menuPosition="static"
                        onChange={(selectedOption) =>
                          setFieldMode(selectedOption.value)
                        }
                        value={TACKLING_FIELD_DISPLAY_MODES.find(
                          (f) => f.value === fieldMode
                        )}
                      />
                      {fieldMode !== TACKLING_FIELD_DISPLAY_PATHS.value && (
                        <Toggle
                          id="field-focus-toggle"
                          label={fieldFocusName()}
                          onChange={() =>
                            setFieldFocusOrigin(!fieldFocusOrigin)
                          }
                          checked={fieldFocusOrigin}
                        />
                      )}
                      {fieldMode === TACKLING_FIELD_DISPLAY_HEATMAP.value && (
                        <Toggle
                          id="show-tackle-attempt-ball-location-toggle"
                          label={
                            fieldFocusOrigin
                              ? 'Show Final Ball Location Dots'
                              : 'Show First Tackle Attempt Dots'
                          }
                          onChange={() => setShowHeatmapDots(!showHeatmapDots)}
                          checked={showHeatmapDots}
                        />
                      )}
                      {fieldMode ===
                        TACKLING_FIELD_DISPLAY_PLAY_LOCATIONS.value &&
                        !fieldFocusOrigin && (
                          <Toggle
                            id="show-first-tackle-attempt-tails-toggle"
                            label="Show First Tackle Attempt Tails"
                            onChange={() =>
                              setShowFirstTackleTails(!showFirstTackleTails)
                            }
                            checked={showFirstTackleTails}
                          />
                        )}
                      {(fieldMode ===
                        TACKLING_FIELD_DISPLAY_PLAY_LOCATIONS.value ||
                        (fieldMode === TACKLING_FIELD_DISPLAY_HEATMAP.value &&
                          showHeatmapDots)) && (
                        <Toggle
                          id="scale-dots-toggle"
                          label="Scale dots by yards after contact"
                          onChange={() => setScaleR(!scaleR)}
                          checked={scaleR}
                        />
                      )}
                      {fieldMode ===
                        TACKLING_FIELD_DISPLAY_PLAY_LOCATIONS.value && (
                        <Dropdown
                          id="color-dots-dropdown"
                          options={tackleColorModes}
                          label="Color Dots"
                          menuPosition="static"
                          onChange={(selectedOption) =>
                            setColorMode(selectedOption.value)
                          }
                          value={tackleColorModes.find(
                            (f) => f.value === colorMode
                          )}
                        />
                      )}
                    </Tile.AccordionBody>
                  }
                />

                <AccordionTile
                  header={
                    <Tile.AccordionHeader>
                      Field Orientation and Size
                    </Tile.AccordionHeader>
                  }
                  body={
                    <Tile.AccordionBody>
                      <Dropdown
                        id="field-rotation-dropdown"
                        options={verticalRotations}
                        label="Field Rotation"
                        menuPosition="static"
                        onChange={(selectedOption) =>
                          setSelectedRotation(selectedOption.value)
                        }
                        value={verticalRotations.find(
                          (f) => f.value === selectedRotation
                        )}
                      />
                      <Toggle
                        id="field-focus-toggle"
                        label="Use Focused Field"
                        onChange={() =>
                          setDisplayXFocusedField(!displayXFocusedField)
                        }
                        checked={displayXFocusedField}
                      />
                    </Tile.AccordionBody>
                  }
                />

                {selectedPlayTable}
              </FixedAside>
            </div>
          </SidebarRightLayout>
        </Grid>
      </Grid>
    </KitbagPageGridHolder>
  );
};

export default TeamTackleAttempts;
