import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useReactiveVar } from '@apollo/client';
import { Breadcrumb, Loader } from 'semantic-ui-react';
import { useLocation } from 'react-router-dom';
import { Dropdown, Grid, Toggle } from '@statsbomb/kitbag-components';
import useQueryString from '../../../utils/hooks/useQueryString';
import { mf_TeamDetails, mf_LeagueLevel, pf_Drive } from '../../../apollo';
import MultiFFChart from './MultiFFChart';
import Tile from '../../../components/Tile/Tile';
import { ROTATIONS, ROTATION_OPTIONS } from '../../../utils/constants/charting';
import {
  SNAP_FORMATION_ZOOM_MODES,
  ZOOM_OPTIONS,
  FORMATION_MEASURES,
  SNAP_FORMATION_FOCUS_MODES,
  SNAP_FORMATION_FOCUS_OPTIONS,
  SNAP_FORMATION_FOCUS_MODES_Y,
  SNAP_FORMATION_FOCUS_OPTIONS_Y,
} from './DataManipulation';
import FFChart from './FFChart';
import FFMeasureOptions from './FFMeasureOptions';
import AccordionTile from '../../../components/Accordion/AccordionTile';
import {
  FixedAside,
  KitbagPageGridHolder,
} from '../../../components/Layout/Layout.styles';
import { StickyEventTile, StyledEventTiles } from './EventTiles.style';
import Dimmer from '../../../components/Dimmer/Dimmer';
import { DROPDOWN_SPECIAL_OPTIONS } from '../../../components/DropdownFilter/DropdownFilter.constants';
import ExportModal from '../../../components/ExportModal/ExportModal';

const EventTiles = ({
  formattedData,
  isError,
  isLoading,
  selectedEvent,
  setSelectedEvent,
  visTitle,
  breadcrumb,
  pageFilters,
  tileList,
  playerFilters,
  selectedOffensePlayerId,
  selectedDefensePlayerId,
  headersArePlays,
}) => {
  const { pathname } = useLocation();
  const competitionLevel = useReactiveVar(mf_LeagueLevel);
  const mf_TeamDetailsRV = useReactiveVar(mf_TeamDetails);
  const [showJerseys, setShowJerseys] = useQueryString('jersey', true);
  const [showBall, setShowBall] = useQueryString('ball', true);
  const [tileCols, setTileCols] = useQueryString('cols', 4);
  const [selectedRotation, setSelectedRotation] = useQueryString(
    'rotation',
    ROTATIONS.VERTICAL_UP
  );
  const [selectedZoom, setSelectedZoom] = useQueryString(
    'zoom',
    SNAP_FORMATION_ZOOM_MODES.DEFAULT
  );
  const [selectedFocusX, setSelectedFocusX] = useQueryString(
    'focusX',
    SNAP_FORMATION_FOCUS_MODES.PLAYERS
  );
  const [selectedFocusY, setSelectedFocusY] = useQueryString(
    'focusY',
    SNAP_FORMATION_FOCUS_MODES_Y.PLAYERS
  );
  const [selectedMeasures, setSelectedMeasures] = useState(FORMATION_MEASURES);

  const tileColsData = [
    { value: 1, label: '1' },
    { value: 2, label: '2' },
    { value: 3, label: '3' },
    { value: 4, label: '4' },
  ];

  const handleSetMeasure = (keyName) => {
    const val = selectedMeasures[keyName];
    const moddedMeasures = { ...selectedMeasures };
    moddedMeasures[keyName] = !val;
    setSelectedMeasures(moddedMeasures);
  };

  const validSelectedEvent = (d, s) =>
    selectedEvent && d?.map((m) => m.id).includes(s);

  const selectedEventName = (d, s) => {
    const eventObj = selectedEvent && d?.find((m) => m.id === s);
    return eventObj?.play?.name || '';
  };

  const marginOverride = { top: 0, bottom: 0, right: 0, left: 0 };

  // export titles and info
  const eventDetails = formattedData?.find((e) => e.game);
  const getSnapFormationsInfo = () => {
    switch (true) {
      case pathname.includes('all'):
        return 'All Drives';
      case pathname.includes('home'):
        return `All Drives for ${eventDetails?.game.homeTeam.name}`;
      case pathname.includes('away'):
        return `All Drives for ${eventDetails?.game.awayTeam.name}`;
      // single drive selected
      default:
        return eventDetails?.play.drive.name;
    }
  };
  const isSnapFormations = pathname.includes('game/formations');
  const gameName = eventDetails?.game.name;
  const driveName = eventDetails?.play.drive.name;
  const playName = eventDetails?.play.name;
  const eventName = eventDetails?.name;
  // multi chart titles and info
  const multiChartSecondaryTitle = isSnapFormations
    ? 'Snap Formations'
    : 'Events in play';
  const multiChartInfo1 = isSnapFormations
    ? getSnapFormationsInfo()
    : driveName;
  const multiChartInfo2 = isSnapFormations ? '' : playName;
  // single chart titles and info
  const singleChartTitle = isSnapFormations ? playName : eventName;
  const singleChartSecondaryTitle = isSnapFormations
    ? 'Snap Measurements'
    : 'Event Diagram';
  const singleChartInfo3 = isSnapFormations ? '' : playName;
  const isChartReady = !isLoading && !isError && !!formattedData?.length;

  return (
    <StyledEventTiles>
      <KitbagPageGridHolder>
        <Grid container={false} page>
          <Grid item xs={10}>
            <Tile border="0" margin="0">
              <Tile.Body>{pageFilters}</Tile.Body>
            </Tile>

            <div style={{ display: 'flex', width: 'calc(100% + 0.5rem)' }}>
              <Grid
                item
                xs={validSelectedEvent(formattedData, selectedEvent) ? 4 : 12}
              >
                <Tile border="0" margin="0">
                  <Tile.Header>
                    {breadcrumb}
                    <h1>{visTitle}</h1>
                    <div className="buttons">
                      <ExportModal
                        title={gameName}
                        secondaryTitle={multiChartSecondaryTitle}
                        info1={multiChartInfo1}
                        info2={multiChartInfo2}
                        fileName={`${mf_TeamDetailsRV?.name} ${multiChartSecondaryTitle}`}
                        isDisabled={!isChartReady}
                      >
                        {isChartReady && (
                          <MultiFFChart
                            id="snap-formations-chart-export"
                            data={formattedData}
                            cols={tileCols}
                            showJerseys={showJerseys}
                            competitionLevel={competitionLevel}
                            selectedEvent={selectedEvent}
                            setSelectedEvent={setSelectedEvent}
                            rotationOption={selectedRotation}
                            zoomOption={selectedZoom}
                            focusOptionX={selectedFocusX}
                            focusOptionY={selectedFocusY}
                            selectedOffensePlayerId={selectedOffensePlayerId}
                            selectedDefensePlayerId={selectedDefensePlayerId}
                            showBall={showBall}
                            playInfo={headersArePlays}
                            isInteractive={false}
                            margin={marginOverride}
                          />
                        )}
                      </ExportModal>
                    </div>
                  </Tile.Header>
                  <Tile.Body>
                    {isChartReady && (
                      <MultiFFChart
                        id="snap-formations-chart"
                        data={formattedData}
                        cols={tileCols}
                        showJerseys={showJerseys}
                        competitionLevel={competitionLevel}
                        selectedEvent={selectedEvent}
                        setSelectedEvent={setSelectedEvent}
                        rotationOption={selectedRotation}
                        zoomOption={selectedZoom}
                        focusOptionX={selectedFocusX}
                        focusOptionY={selectedFocusY}
                        selectedOffensePlayerId={selectedOffensePlayerId}
                        selectedDefensePlayerId={selectedDefensePlayerId}
                        showBall={showBall}
                        playInfo={headersArePlays}
                      />
                    )}
                    {formattedData?.length === 0 && (
                      <Dimmer active>
                        <p>No Data available for this query.</p>
                        <p>
                          If you are expecting data you may need to adjust the
                          filters.
                        </p>
                      </Dimmer>
                    )}
                    {isLoading && (
                      <Dimmer active>
                        <Loader content="Loading" />
                      </Dimmer>
                    )}
                  </Tile.Body>
                </Tile>
              </Grid>
              {validSelectedEvent(formattedData, selectedEvent) && (
                <Grid item xs={8}>
                  <StickyEventTile>
                    <Tile border="0" margin="0">
                      <Tile.Header>
                        <Breadcrumb size="huge">
                          <Breadcrumb.Section>Game</Breadcrumb.Section>
                          <Breadcrumb.Divider />
                          <Breadcrumb.Section active>
                            Snap Formations
                          </Breadcrumb.Section>
                        </Breadcrumb>
                        <h1>
                          {`${selectedEventName(
                            formattedData,
                            selectedEvent
                          )} - Detailed
                          Measures`}
                        </h1>
                        <div className="buttons">
                          <ExportModal
                            title={singleChartTitle}
                            secondaryTitle={singleChartSecondaryTitle}
                            info1={gameName}
                            info2={driveName}
                            info3={singleChartInfo3}
                            enlarged
                            fileName={`${mf_TeamDetailsRV?.name} Snap Formation Detail`}
                            isDisabled={!isChartReady}
                          >
                            {isChartReady && (
                              <FFChart
                                id="snap-formations-chart-single-export"
                                data={formattedData}
                                cols={tileCols}
                                competitionLevel={competitionLevel}
                                selectedEvent={selectedEvent}
                                rotationOption={selectedRotation}
                                zoomOption={selectedZoom}
                                focusOptionX={selectedFocusX}
                                focusOptionY={selectedFocusY}
                                showJerseys={showJerseys}
                                measureOptions={selectedMeasures}
                                selectedOffensePlayerId={
                                  selectedOffensePlayerId
                                }
                                selectedDefensePlayerId={
                                  selectedDefensePlayerId
                                }
                                showBall={showBall}
                                margin={marginOverride}
                              />
                            )}
                          </ExportModal>
                        </div>
                      </Tile.Header>
                      <Tile.Body>
                        {isChartReady && (
                          <FFChart
                            id="snap-formations-chart-single"
                            data={formattedData}
                            cols={tileCols}
                            competitionLevel={competitionLevel}
                            selectedEvent={selectedEvent}
                            rotationOption={selectedRotation}
                            zoomOption={selectedZoom}
                            focusOptionX={selectedFocusX}
                            focusOptionY={selectedFocusY}
                            showJerseys={showJerseys}
                            measureOptions={selectedMeasures}
                            selectedOffensePlayerId={selectedOffensePlayerId}
                            selectedDefensePlayerId={selectedDefensePlayerId}
                            showBall={showBall}
                          />
                        )}
                        {formattedData?.length === 0 && (
                          <Dimmer active>
                            <p>
                              No Data available for this query. If you are
                              expecting data you may need to adjust the filters.
                            </p>
                          </Dimmer>
                        )}
                        {isLoading && (
                          <Dimmer active>
                            <Loader content="Loading" />
                          </Dimmer>
                        )}
                      </Tile.Body>
                    </Tile>
                  </StickyEventTile>
                </Grid>
              )}
            </div>
          </Grid>

          <Grid item xs={2}>
            <FixedAside $width="auto">
              <AccordionTile
                header={<Tile.AccordionHeader>Settings</Tile.AccordionHeader>}
                body={
                  <>
                    <Tile.AccordionBody>
                      <Toggle
                        id="display-jersey-toggle"
                        label="Display Jerseys"
                        onChange={() => setShowJerseys(!showJerseys)}
                        checked={showJerseys}
                      />

                      <Toggle
                        id="display-ball-toggle"
                        label="Display Ball"
                        onChange={() => setShowBall(!showBall)}
                        checked={showBall}
                      />
                      <Dropdown
                        id="columns-to-display-dropdown"
                        options={tileColsData}
                        label="Columns to Display"
                        onChange={(selectedOption) =>
                          setTileCols(selectedOption.value)
                        }
                        value={tileColsData.find((f) => f.value === tileCols)}
                        menuPosition="static"
                      />

                      <Dropdown
                        id="field-rotation-dropdown"
                        options={ROTATION_OPTIONS}
                        label="Field Rotation"
                        onChange={(selectedOption) =>
                          setSelectedRotation(selectedOption.value)
                        }
                        value={
                          ROTATION_OPTIONS.filter(
                            (f) => f.value === selectedRotation
                          )[0]
                        }
                        menuPosition="static"
                      />

                      <Dropdown
                        id="zoom-mode-dropdown"
                        options={ZOOM_OPTIONS}
                        label="Zoom Mode"
                        onChange={(selectedOption) =>
                          setSelectedZoom(selectedOption.value)
                        }
                        value={
                          ZOOM_OPTIONS.filter(
                            (f) => f.value === selectedZoom
                          )[0]
                        }
                        menuPosition="static"
                      />

                      <Dropdown
                        id="focus-modex-dropdown"
                        options={SNAP_FORMATION_FOCUS_OPTIONS}
                        label="Focus Point (field X)"
                        onChange={(selectedOption) =>
                          setSelectedFocusX(selectedOption.value)
                        }
                        value={
                          SNAP_FORMATION_FOCUS_OPTIONS.filter(
                            (f) => f.value === selectedFocusX
                          )[0]
                        }
                        menuPosition="static"
                      />

                      <Dropdown
                        id="focus-modey-dropdown"
                        options={SNAP_FORMATION_FOCUS_OPTIONS_Y}
                        label="Focus Point (field Y)"
                        onChange={(selectedOption) =>
                          setSelectedFocusY(selectedOption.value)
                        }
                        value={
                          SNAP_FORMATION_FOCUS_OPTIONS_Y.filter(
                            (f) => f.value === selectedFocusY
                          )[0]
                        }
                        menuPosition="static"
                      />
                    </Tile.AccordionBody>
                  </>
                }
                isExpandedDefault
                isMount
              />
              {validSelectedEvent(formattedData, selectedEvent) && (
                <AccordionTile
                  header={
                    <Tile.AccordionHeader>
                      Selected Measures
                    </Tile.AccordionHeader>
                  }
                  body={
                    <Tile.AccordionBody>
                      <FFMeasureOptions
                        selectedMeasures={selectedMeasures}
                        handleSetMeasure={handleSetMeasure}
                        handleSetAllMeasures={setSelectedMeasures}
                      />
                    </Tile.AccordionBody>
                  }
                  isExpandedDefault={false}
                  isMount
                />
              )}
              {playerFilters?.props?.children && (
                <AccordionTile
                  header={<Tile.AccordionHeader>Players</Tile.AccordionHeader>}
                  body={playerFilters}
                  isExpandedDefault
                  isMount
                />
              )}
              {!DROPDOWN_SPECIAL_OPTIONS.includes(pf_Drive()) && (
                <AccordionTile
                  header={
                    <Tile.AccordionHeader>
                      {headersArePlays ? 'All Plays in Drive' : 'Events'}
                    </Tile.AccordionHeader>
                  }
                  body={tileList}
                  isExpandedDefault
                  isMount
                />
              )}
            </FixedAside>
          </Grid>
        </Grid>
      </KitbagPageGridHolder>
    </StyledEventTiles>
  );
};

EventTiles.propTypes = {
  formattedData: PropTypes.arrayOf(PropTypes.object),
  isError: PropTypes.bool,
  isLoading: PropTypes.bool,
  selectedEvent: PropTypes.string,
  setSelectedEvent: PropTypes.func,
  visTitle: PropTypes.string,
  breadcrumb: PropTypes.element,
  pageFilters: PropTypes.element,
  tileList: PropTypes.element,
  playerFilters: PropTypes.element,
  selectedOffensePlayerId: PropTypes.number,
  selectedDefensePlayerId: PropTypes.number,
  headersArePlays: PropTypes.bool,
};

EventTiles.defaultProps = {
  formattedData: null,
  isError: false,
  isLoading: false,
  selectedEvent: null,
  setSelectedEvent: null,
  visTitle: 'Event Tiles',
  breadcrumb: <></>,
  pageFilters: <></>,
  tileList: <></>,
  playerFilters: <></>,
  selectedOffensePlayerId: 0,
  selectedDefensePlayerId: 0,
  headersArePlays: true,
};
export default EventTiles;
